import { Language } from "../globalState/reducers/Language.reducer";
import { PaymentSettingsConfigurationMerchant } from "./GeneralSetting";

interface Parrain {
    uid: string;
    first_name: string;
    last_name: string;
}

interface Tutor {
    uid: string;
    first_name: string;
    last_name: string;
}

interface JointAccount {
    uid: string;
    first_name: string;
    last_name: string;
}

export enum UserType {
    PARTICULIER = "particulier",
    ENTERPRISE = "enterprise",
    JOIN = "join",
}
export default class User {
    id?: number;
    uid: string;
    reinvest_auto: boolean;
    hide_zero_amount: boolean;
    hide_not_active_earning?: boolean;
    user_name: string;
    last_name: string;
    first_name: string;
    email: string;
    scopes?: string[];
    photo?: string;
    parrain?: Parrain;
    haveTransactionPassword?: boolean;
    created_at?: Date;
    cap?: number;
    cag?: number;
    is_active?: boolean;
    is_fiat?: boolean;
    kyc?: string;
    type?: UserType;
    entreprise?: string;
    siret?: string;
    fidelity?: any;
    currency?: string;
    address?: string;
    post_code?: string;
    city?: string;
    date_of_birth?: string;
    country?: string;
    phone_country_code?: string;
    phone?: string;
    base_currency?: any;
    monthly_expenses_limit?: number;
    can_use_crypto?: boolean;
    can_transfert_crypto?: boolean;
    can_close_earning?: boolean;
    can_create_jackpot?: boolean;
    is_parent?: boolean;
    tutor?: Tutor;
    allow_the_child_to_purchase_assets?: boolean;
    allow_child_deposit_assets?: boolean;
    allow_child_to_trade_assets?: boolean;
    allow_the_child_to_transfer_assets?: boolean;
    global_solde?: number;
    principal_solde?: number;
    join_account?: JointAccount;
    join_parents?: User[];
    merchant_app_id?: string;
    merchant_app_key?: number;
    is_perso?: boolean;
    merchant_enable_payment?: boolean;
    merchant_enable_transfer?: boolean;
    setting?: any;
    inf_app_fee?: number;
    widget_setting?: string;
    theme_setting?: string;
    language: Language;
    backgrounds: string[];

    solde_principal?: number;
    available?: number;
    blocked?: number;
    market_trends?: any;
    fiats?: any;
    merchant_payment_configuration?: PaymentSettingsConfigurationMerchant;

    constructor(
        uid: string,
        user_name: string,
        last_name: string,
        first_name: string,
        email: string,
        reinvest_auto: boolean,
        hide_zero_amount: boolean,
        country: string,
        language: Language,
        backgrounds: string[],
    ) {
        this.uid = uid;
        this.user_name = user_name;
        this.last_name = last_name;
        this.first_name = first_name;
        this.email = email;
        this.reinvest_auto = reinvest_auto;
        this.hide_zero_amount = hide_zero_amount;
        this.country = country;
        this.language = language;
        this.backgrounds = backgrounds;
    }
}

export interface IUserSignUp {
    email: string;
    first_name: string;
    last_name: string;
    username?: string;
    password: string;
    uid_parrain?: string;
    token?: string | null;

    type?: string;
    entreprise?: string;
    siret?: string;
}

export interface IUserPasswordUpdate {
    oldPassword: string;
    newPassword: string;
}
export interface IUserPasswordReset {
    new_password: string;
    confirm_password: string;
    token: string;
}

export interface IUserTransactionPasswordCreate {
    transactionPassword: string;
}

export interface User2Fa {
    key?: string;
    application?: string;
    username?: string;
    status: boolean;
}
export interface Token2Fa {
    token: string;
}
export interface IUserRequestResetPassword {
    email: string;
    url: string;
}
export interface DirectActifCount {
    children_with_deleted: number;
    children: number;
    child_grandchild: number;
    nb_direct_actif: number;
    wallet_amount: number;
}

export interface PublicUserDta {
    email: string;
    first_name: string;
    last_name: string;
    username: string | null | undefined;
    photo: string | null | undefined;
    uid: string;
    cap?: number;
    cag?: number;
    is_active?: boolean;
    children: PublicUserDta[];
}

export interface ICommissionType {
    type: boolean;
}

export interface IEditProfil {
    uid: string;
    email: string;
}

export interface IResetTransactionPassword {
    code: string;
    new_password: string;
}

export interface IAddAffiliation {
    uid_to_add: string;
    uid_parrain: string;
}

export interface HeaderFilter {
    [key: string]: {
        filter: boolean;
        is_asc: boolean;
    };
}
export interface IUserSetting {
    card_details: boolean;
    repartition_actif: boolean;
    evolution_account: boolean;
    cash_flow: boolean;
    infinity_points: boolean;
    market_trend: boolean;
}

export interface IAmbassador {
    last_name: string;
    first_name: string;
    phone: string;
    email: string;
    country: string;
    status: string;
    insta_profil?: string;
    youtube_profil?: string;
    tiktok_profil?: string;
    facebook_profil?: string;
    snapchat_profil?: string;
    other_profil?: string;
    number_of_community: number;
    activity_area: string;
    description: string;
}

export interface DNDProps {
    user: User;
    pnlData: any;
    creditDebit: any;
}

export interface WidgetCard {
    id: string;
    text: string;
    content: JSX.Element;
}
export interface WidgetSetting {
    setting: string;
}
export interface ThemeSetting {
    background: {
        dashboard: string;
        wallet: string;
        staking: string;
        account18: Account18Theme[];
        userWallet: UserWalletTheme[];
        cryptos: string;
    };
}

export interface Account18Theme {
    uid: string;
    color: string;
}

export interface UserWalletTheme {
    id: string;
    color: string;
}
