import {
    AbonnementStats,
    TotalFluxAndFees,
    UserCountData,
} from "../../Models/Comptability";
import { FidelityOrderAccounting } from "../../Models/FidelityOrder";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const getData = async (): Promise<any> => {
    return axios
        .get(`/comptability`)
        .then((res) => {
            return res.data as any;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getSumFee = async (): Promise<any> => {
    return axios
        .get(`/comptability/get-sum-fee`)
        .then((res) => {
            return res.data as any;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getFidelityOrderSum = async (): Promise<FidelityOrderAccounting[]> => {
    return axios
        .get(`/fidelity-subscription/fidelity-accounting`)
        .then((res) => {
            return res.data as FidelityOrderAccounting[];
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGeneralReport = async (): Promise<any> => {
    return axios
        .get(`/dashboard/general-report`)
        .then((res) => {
            return res.data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getSumAmountFee = async (): Promise<any> => {
    return axios
        .get(`/dashboard/get-sum-amount-fee`)
        .then((res) => {
            return res.data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getCountUserData = async (): Promise<any> => {
    return axios
        .get(`/dashboard/get-count-user-data`)
        .then((res) => {
            return res.data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserStatsData = async (): Promise<any> => {
    return axios
        .get(`/dashboard/get-user-stats`)
        .then((res) => {
            return res.data as UserCountData;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getStatsAbonnement = async (): Promise<any> => {
    return axios
        .get(`/dashboard/get-stats-abonnement`)
        .then((res) => {
            return res.data as AbonnementStats;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getTotalFluxAndFees = async (): Promise<any> => {
    return axios
        .get(`/dashboard/get-total-flux-and-fees`)
        .then((res) => {
            return res.data as TotalFluxAndFees;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const ComptabilityService = {
    getData,
    getFidelityOrderSum,
    getSumFee,
    getGeneralReport,
    getSumAmountFee,
    getCountUserData,
    getUserStatsData,
    getStatsAbonnement,
    getTotalFluxAndFees,
};

export default ComptabilityService;
