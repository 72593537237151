export interface UserCategoryStats {
    active: number;
    inactive: number;
    total: number;
}

export interface UserCountData {
    allUsers: UserCategoryStats;
    individualUsers: UserCategoryStats;
    businessUsers: UserCategoryStats;
    joinUsers: UserCategoryStats;
}

export interface dataAmount {
    sum_usd_price: number;
    sum_fee: number;
    percent: number;
}

export interface propsTypeAbonnement {
    data: dataAmount;
    gold: dataAmount;
    black: dataAmount;
    blue: dataAmount;
}

export interface AbonnementStats {
    particulier: {
        particulier: dataAmount;
        gold: dataAmount;
        black: dataAmount;
        blue: dataAmount;
    };
    business: {
        business: dataAmount;
        gold: dataAmount;
        black: dataAmount;
        blue: dataAmount;
    };
}

export class AbonnementStatsCalculator {
    private stats: any;

    constructor(stats: any) {
        this.stats = stats || this.getDefaultStats();
    }

    private calculatePercentage(price: any, total: any): number {
        total = Number(total);
        price = Number(price);
        if (total === 0) return 0;
        return (price / total) * 100;
    }

    private getDefaultStats() {
        return {
            particulier: {
                black: { sum_usd_price: 0, sum_fee: 0, percent: 0 },
                gold: { sum_usd_price: 0, sum_fee: 0, percent: 0 },
                blue: { sum_usd_price: 0, sum_fee: 0, percent: 0 },
                particulier: { sum_usd_price: 0, sum_fee: 0, percent: 0 },
            },
            business: {
                black: { sum_usd_price: 0, sum_fee: 0, percent: 0 },
                gold: { sum_usd_price: 0, sum_fee: 0, percent: 0 },
                blue: { sum_usd_price: 0, sum_fee: 0, percent: 0 },
                business: { sum_usd_price: 0, sum_fee: 0, percent: 0 },
            },
        };
    }

    public calculatePercentages(): void {
        if (!this.stats) return;

        // Particulier
        this.stats.particulier.black.percent = this.calculatePercentage(
            this.stats.particulier.black.sum_usd_price +
                this.stats.particulier.black.sum_fee,
            this.stats.particulier.particulier.sum_usd_price,
        );
        this.stats.particulier.gold.percent = this.calculatePercentage(
            this.stats.particulier.gold.sum_usd_price +
                this.stats.particulier.gold.sum_fee,
            this.stats.particulier.particulier.sum_usd_price,
        );
        this.stats.particulier.blue.percent = this.calculatePercentage(
            this.stats.particulier.blue.sum_usd_price +
                this.stats.particulier.blue.sum_fee,
            this.stats.particulier.particulier.sum_usd_price,
        );

        // Business
        this.stats.business.gold.percent = this.calculatePercentage(
            this.stats.business.gold.sum_usd_price +
                this.stats.business.gold.sum_fee,
            this.stats.business.business.sum_usd_price,
        );
        this.stats.business.black.percent = this.calculatePercentage(
            this.stats.business.black.sum_usd_price +
                this.stats.business.black.sum_fee,
            this.stats.business.business.sum_usd_price,
        );
        this.stats.business.blue.percent = this.calculatePercentage(
            this.stats.business.blue.sum_usd_price +
                this.stats.business.blue.sum_fee,
            this.stats.business.business.sum_usd_price,
        );
    }

    public getStats(): AbonnementStats {
        return this.stats;
    }
}

export interface amountFee {
    amount: number;
    fee: number;
}
export interface TotalFluxAndFees {
    deposit: amountFee;
    exchange: amountFee;
    retrait: amountFee;
    paiements_marchand: amountFee;
    gateway_payeer: amountFee;
    gateway_cb: amountFee;
    rechargement_cb: amountFee;
    achats_crypto: amountFee;
}
