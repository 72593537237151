import React, { useState, useEffect } from "react";
import { useParams, useLocation, NavLink } from "react-router-dom";
import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";

const Various = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const { stepType } = useParams<{ stepType: string }>(); // Récupère le paramètre 'accountType'
    const location = useLocation(); // Utilisation de useLocation pour suivre les changements d'URL
    const [currentStep, setCurrentStep] = useState<string>(stepType || ""); // Initialise avec accountType de l'URL

    // Utilisation de useEffect pour écouter les changements d'URL
    useEffect(() => {
        // Si l'accountType change dans l'URL, on met à jour l'état currentStep
        if (stepType && currentStep !== stepType) {
            setCurrentStep(stepType);
        }
    }, [stepType, location]); // On réagit aux changements de l'accountType et de l'URL

    const getBannerTitle = () => {
        switch (currentStep) {
            case "contact-customer-service":
                return translate("HELPCENTER", "How_Customer_Service");
            case "working-with-infinexia":
                return translate("HELPCENTER", "Working_With_Infinexia");
            case "press-service":
                return translate("HELPCENTER", "How_Press service");
            default:
                return translate("HELPCENTER", "Various");
        }
    };

    const getBreadcrumb = () => {
        switch (currentStep) {
            case "contact-customer-service":
                return translate("HELPCENTER", "Customer_Service");
            case "working-with-infinexia":
                return translate("HELPCENTER", "Working_With_Infinexia");
            case "press-service":
                return translate("HELPCENTER", "Press service");
            default:
                return translate("HELPCENTER", "Various");
        }
    };

    const renderContentForStep = () => {
        switch (currentStep) {
            case "contact-customer-service":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="contact-customer-service"
                                    title={translate(
                                        "HELPCENTER_VARIOUS",
                                        "Contact_Customer_Service",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_VARIOUS",
                                        "Customer_Service_Sub",
                                    )}
                                </p>
                                <p className="pt-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <SubTitle
                                        id="contact-customer-service"
                                        title={translate(
                                            "HELPCENTER_VARIOUS",
                                            "Even_Though_Infinexia",
                                        )}
                                    />
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_VARIOUS",
                                        "Our_Team_Generally_Responds",
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <p className="sm:!text-sm md:!text-base text-black font-bold">
                                {translate("HELPCENTER_VARIOUS", "Need_Help")}{" "}
                                <a
                                    href="javascript:void(Tawk_API.toggle())"
                                    className="text-blue-500 underline hover:text-blue-700 font-normal"
                                >
                                    {translate("HELPCENTER", "Send_Us_Message")}
                                </a>
                            </p>
                        </div>
                        <div className="md:hidden py-5">
                            <p className="sm:!text-sm md:!text-base text-center text-black font-bold">
                                {translate("HELPCENTER_VARIOUS", "Need_Help")}
                            </p>
                            <p className="sm:!text-sm md:!text-base text-center text-black font-normal">
                                <a
                                    href="javascript:void(Tawk_API.toggle())"
                                    className="text-blue-500 underline hover:text-blue-700"
                                >
                                    {translate("HELPCENTER", "Send_Us_Message")}
                                </a>
                            </p>
                        </div>
                    </>
                );
            case "working-with-infinexia":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="working-with-infinexia"
                                    title={translate(
                                        "HELPCENTER_VARIOUS",
                                        "Would_You_Like_To_Work",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_VARIOUS",
                                        "Would_You_Like_To_Work2",
                                    )}
                                </p>

                                <div className="pt-5 space-y-6">
                                    <div className="space-y-0">
                                        <SubTitle
                                            id=""
                                            title={translate(
                                                "HELPCENTER_REWARDS",
                                                "Ambassador_Become",
                                            )}
                                        />
                                        <p className="sm:!text-sm md:!text-base text-black font-normal">
                                            {translate(
                                                "HELPCENTER",
                                                "Rdv_To_Page",
                                            )}{" "}
                                            <strong>
                                                «
                                                <NavLink
                                                    to={`${lang ? `/${lang}` : "/partners"}/partners`}
                                                    className="text-blue-600 underline hover:text-blue-800"
                                                >
                                                    {translate(
                                                        "HELPCENTER_REWARDS",
                                                        "Rdv_Ambassador_Page",
                                                    )}
                                                </NavLink>
                                                »
                                            </strong>{" "}
                                            {translate("HELPCENTER", "Steps")}
                                        </p>
                                        <ul className="pt-2 list-disc pl-5 sm:!text-sm md:!text-base text-black font-normal">
                                            <li>
                                                {translate(
                                                    "HELPCENTER_REWARDS",
                                                    "Ambassador_Form",
                                                )}
                                            </li>
                                            <li>
                                                {translate(
                                                    "HELPCENTER",
                                                    "And_Valid_Onclick",
                                                )}{" "}
                                                <strong>
                                                    «
                                                    {translate(
                                                        "HELPCENTER",
                                                        "Send",
                                                    )}
                                                    »
                                                </strong>
                                                .
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="pt-5 space-y-6">
                                    <div className="space-y-0">
                                        <SubTitle
                                            id=""
                                            title={translate(
                                                "HELPCENTER_VARIOUS",
                                                "Contact",
                                            )}
                                        />
                                        <p className="sm:!text-sm md:!text-base text-black font-normal">
                                            {translate(
                                                "HELPCENTER_VARIOUS",
                                                "Contact_Collab",
                                            )}
                                        </p>
                                        <p className="sm:!text-sm md:!text-base text-black font-bold">
                                            <a
                                                href="javascript:void(Tawk_API.toggle())"
                                                className="text-blue-500 underline hover:text-blue-700 font-normal"
                                            >
                                                {translate(
                                                    "HELPCENTER",
                                                    "Send_Us_Message",
                                                )}
                                            </a>
                                        </p>
                                    </div>
                                </div>

                                <p className="pt-5 sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_VARIOUS",
                                        "Our_Team_Generally_Responds",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            case "press-service":
                return (
                    <>
                        <div className="space-y-6">
                            <div className="space-y-0">
                                <SubTitle
                                    id="press-service"
                                    title={translate(
                                        "HELPCENTER_VARIOUS",
                                        "Are_You_A_Press_Service",
                                    )}
                                />
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_VARIOUS",
                                        "You_Are_A_Journalist",
                                    )}
                                </p>
                                <p className="pt-5 sm:!text-sm md:!text-base text-black font-normal">
                                    <SubTitle
                                        id="contact-customer-service"
                                        title={translate(
                                            "HELPCENTER_VARIOUS",
                                            "You_Can_Contact_Us",
                                        )}
                                    />
                                </p>
                                <p className="sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_VARIOUS",
                                        "You_Can_Contact_Us2",
                                    )}
                                </p>
                                <p className="pt-2 sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_VARIOUS",
                                        "Or_Contact_Us",
                                    )}{" "}
                                    <a
                                        href="javascript:void(Tawk_API.toggle())"
                                        className="text-blue-500 underline hover:text-blue-700 font-normal"
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Send_Us_Message",
                                        )}
                                    </a>
                                </p>
                                <p className="pt-5 sm:!text-sm md:!text-base text-black font-normal">
                                    {translate(
                                        "HELPCENTER_VARIOUS",
                                        "Our_Team_Generally_Responds",
                                    )}
                                </p>
                            </div>
                        </div>
                    </>
                );
            default:
                return null; // Aucun contenu par défaut si le type de compte n'est pas reconnu
        }
    };

    return (
        <>
            <Banner title={getBannerTitle()} />
            <div className="breadcrumb md:hidden flex items-center mb-8">
                <div className="flex items-center text-sm">
                    {/* Lien vers l'accueil Help Center */}
                    <NavLink
                        to={`${lang ? `/${lang}` : ""}/help-center/various`}
                        className="text-slate-600 dark:text-slate-600 hover:text-slate-800 dark:hover:text-slate-800 transition duration-150 ease-in-out"
                    >
                        {translate("HELPCENTER", "Divers")}
                    </NavLink>

                    {/* Flèche de séparation */}
                    <svg
                        className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-400"
                        width="8"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                    </svg>

                    {/* Titre de l'étape (Fundamentals ou autre) */}
                    <span className="text-slate-800 font-medium truncate dark:text-slate-800">
                        {getBreadcrumb()}
                    </span>
                </div>
            </div>
            {/* Fin du breadcrumb */}
            <article className="flex xl:space-x-12">
                <div className="min-w-0">
                    <div>
                        <div className="text-slate-600 dark:text-slate-600 space-y-6">
                            {/* Affiche le contenu selon currentStep */}
                            {renderContentForStep()}
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Various;
