import React, { useState } from "react";
import UtilsService, { formatDecimal } from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import { propsTypeAbonnement } from "../../../Models/Comptability";

const CardInfoAbonnement = ({
    abonnementStats,
    label,
    isFee,
    baseCurrency,
}: {
    abonnementStats: propsTypeAbonnement;
    label: string;
    isFee: boolean;
    baseCurrency?: any;
}) => {
    const { translate } = translator();
    return isFee ? (
        <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3 h-full">
            <div className="report-box h-full dark:border-2 dark:border-slate-700 dark:rounded-md">
                <div className="box p-5 h-full">
                    <div className="flex">
                        <span className="text-base">{label}</span>
                        <div className="ml-auto"></div>
                    </div>
                    <div className="mt-6  font-medium leading-8">
                        <span>
                            {" "}
                            {translate("Checkout_API", "Montant")}{" "}
                            {baseCurrency
                                ? UtilsService.getConvertedFormatedAmount(
                                      abonnementStats.data.sum_usd_price,
                                      baseCurrency,
                                      2,
                                  )
                                : ""}
                        </span>{" "}
                        <br />
                        <span>
                            {" "}
                            {translate(
                                "Admin_Withdrawal_Request",
                                "Fee",
                            )} :{" "}
                            {baseCurrency
                                ? UtilsService.getConvertedFormatedAmount(
                                      abonnementStats.data.sum_fee,
                                      baseCurrency,
                                      2,
                                  )
                                : ""}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <>
            <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3 h-full">
                <div className="report-box h-full dark:border-2 dark:border-slate-700 dark:rounded-md">
                    <div className="box p-5 h-full">
                        <div className="flex justify-between items-left">
                            <span className="text-base"> Blue </span>
                            <div className="ml-auto"></div>
                            <div
                                className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                title=""
                            >
                                {abonnementStats
                                    ? formatDecimal(
                                          abonnementStats.blue.percent,
                                          2,
                                      )
                                    : "-"}
                                %
                                <i
                                    data-lucide="chevron-up"
                                    className="ml-0.5 h-4 w-1"
                                ></i>
                            </div>
                        </div>
                        <div className="mt-6 font-medium leading-8">
                            <span>
                                {" "}
                                {translate("Checkout_API", "Montant")}{" "}
                                {baseCurrency
                                    ? UtilsService.getConvertedFormatedAmount(
                                          abonnementStats.blue.sum_usd_price,
                                          baseCurrency,
                                          2,
                                      )
                                    : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3 h-full">
                <div className="report-box h-full dark:border-2 dark:border-slate-700 dark:rounded-md">
                    <div className="box p-5 h-full">
                        <div className="flex">
                            <span className="text-base"> Gold </span>
                            <div className="ml-auto"></div>
                            <div
                                className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                title=""
                            >
                                {abonnementStats
                                    ? formatDecimal(
                                          abonnementStats.gold.percent,
                                          2,
                                      )
                                    : "-"}
                                %
                                <i
                                    data-lucide="chevron-up"
                                    className="ml-0.5 h-4 w-1"
                                ></i>
                            </div>
                        </div>
                        <div className="mt-6 font-medium leading-8">
                            <span>
                                {" "}
                                {translate("Checkout_API", "Montant")}{" "}
                                {baseCurrency
                                    ? UtilsService.getConvertedFormatedAmount(
                                          abonnementStats.gold.sum_usd_price,
                                          baseCurrency,
                                          2,
                                      )
                                    : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6 xl:col-span-3 h-full">
                <div className="report-box h-full dark:border-2 dark:border-slate-700 dark:rounded-md">
                    <div className="box p-5 h-full">
                        <div className="flex">
                            <span className="text-base"> Black </span>
                            <div className="ml-auto"></div>
                            <div
                                className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                title=""
                            >
                                {abonnementStats
                                    ? formatDecimal(
                                          abonnementStats.black.percent,
                                          2,
                                      )
                                    : "-"}
                                %
                                <i
                                    data-lucide="chevron-up"
                                    className="ml-0.5 h-4 w-1"
                                ></i>
                            </div>
                        </div>
                        <div className="mt-6 font-medium leading-8">
                            <span>
                                {" "}
                                {translate("Checkout_API", "Montant")}{" "}
                                {baseCurrency
                                    ? UtilsService.getConvertedFormatedAmount(
                                          abonnementStats.black.sum_usd_price,
                                          baseCurrency,
                                          2,
                                      )
                                    : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CardInfoAbonnement;
