import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import AdminGeneralReportAccounting from "./AdminGeneralReportAccounting";
import AdminFluxFeesAccounting from "./AdminFluxFeesAccounting";
import AdminWalletsAccounting from "./AdminWalletsAccounting";
import AdminIndividualAccounting from "./AdminIndividualAccounting";
import AdminBusinessAccounting from "./AdminBusinessAccounting";
import SubscriptionAbonnement from "./SubscriptionAbonnement";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);

export interface Raport {
    cb: 0;
    sum_amount_kalypay: 0;
    sum_fidelity: 0;
    sum_withdrawal: 0;
    fee_swap: 0;
    swap: 0;
    sum_commission: 0;
    count_user: {
        total: 0;
        active: 0;
    };
}
const AdminFinancial: React.FC = () => {
    return (
        <div>
            <div className="intro-y mt-10 md:!mt-7 mb-2 items-center mx-0 px-0 grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="col-span-12">
                                <AdminGeneralReportAccounting />
                            </div>
                            <div className="col-span-12">
                                {/* <AdminSubscriptionsAccounting /> */}
                                <SubscriptionAbonnement />
                            </div>
                            <div className="col-span-12">
                                <AdminFluxFeesAccounting />
                            </div>
                            <div className="col-span-12">
                                <AdminIndividualAccounting />
                            </div>
                            <div className="col-span-12">
                                <AdminBusinessAccounting />
                            </div>
                            <div className="col-span-12">
                                <AdminWalletsAccounting />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminFinancial;
