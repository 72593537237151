import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "../../Components/Extra/Dropdown/Dropdown";
import Notification from "../../Components/Extra/Notification/Notification";
import NotificationInformation from "../../Components/Extra/Notification/NotificationInformation";
import CloseMenuSvg from "../../Components/Extra/Svg/CloseMenuSvg";
import RankSvg from "../../Components/Extra/Svg/RankSvg";
import translator from "../../Components/Extra/Translation/Translate";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import UtilsService from "../../Services/UtilsService";
import { useAppDispatch } from "../../globalState/hook";
import {
    ChangeAppLanguage,
    Language,
} from "../../globalState/reducers/Language.reducer";
import AccountMenu from "./MenuList";
import ItemListMobile from "./Nav/ItemListMobile";
import TransactionService from "../../Services/Wallets/TransactionService";
import { OTransactionCount } from "../../Models/Transaction";
import LogoutSettingsSvg from "../../Components/Extra/Svg/LogoutSettingsSvg";
import LogoutSideSvg from "../../Components/Extra/Svg/LogoutSideSvg";
import LogoutSidePcSvg from "../../Components/Extra/Svg/LogoutSidePcSvg";
import UserRoundCogDropdownSvg from "../../Components/Extra/Svg/UserRoundCogDropdownSvg";
import { useAppProvider } from "../../App/Provider/AppProvider";
import FidelityModel from "../../Models/Fidelity";
import FidelityService from "../../Services/FidelityService/FidelityService";
import CreditCardSvg from "../../Components/Extra/Svg/CreditCardSvg";
import { UserType } from "../../Models/User";
import CryptosSvg from "../../Components/Extra/Svg/CryptosSvg";
import CryptosDownMenuSvg from "../../Components/Extra/Svg/CryptosDownMenuSvg";
import FramerSvg from "../../Components/Extra/Svg/FramerSvg";

const SideMobile = () => {
    const navigate = useNavigate();
    const { translate } = translator();
    const { incrementAction } = useAppProvider();
    const { user, isAdmin, isAdvisor, isAdmin_n2, isSuperAdmin } =
        useAuthentication();
    const isCompany = user?.type === UserType.ENTERPRISE;
    const location = useLocation();

    const toggleExanded = (e: any) => {
        e.preventDefault();
    };

    const hangleCLick = (path: string, classname: string) => {
        navigate(path);
    };

    const hangleCLickBlank = (path: string, classname: string) => {
        window.open(path, "_blank");
    };

    const is_minor =
        user && UtilsService.isMinor(new Date(user?.date_of_birth || ""));

    const [transactionCount, setTransactionCount] = useState<
        OTransactionCount | undefined
    >();

    const [currentLoyalty, setCurrentLoyalty] = useState<
        FidelityModel | undefined
    >();

    const getLoyalty = async () => {
        try {
            const res = await FidelityService.getLastValidateCard();
            setCurrentLoyalty(res);
        } catch (err: any) {}
    };

    const getTransactionCount = async () => {
        const res = await TransactionService.getAllTransactionsCount();
        setTransactionCount(res);
    };

    const isAdminFi = user && user.scopes && user.scopes.includes("admin-fi");

    const getCountValue = (path: string) => {
        let result = 0;
        switch (path) {
            case "/transactions":
                result = transactionCount?.all || 0;
                break;
            case "/withdrawal-request":
                result = transactionCount?.withdraw || 0;
                break;
            case "/deposit-request":
                result = transactionCount?.deposit || 0;
                break;
            case "/all-transactions":
                result = transactionCount?.all_transactions || 0;
                break;
            case "/moderation":
                result = transactionCount?.moderation || 0;
                break;
            case "/subscriptions-orders-list":
                result = transactionCount?.card || 0;
                break;
            case "/cards-orders":
                result = transactionCount?.card || 0;
                break;
            case "/api-access-request":
                result = transactionCount?.api_access_request || 0;
                break;
        }

        return result;
    };

    useEffect(() => {
        if (isAdmin || isAdvisor) getTransactionCount();
    }, []);

    const [scroll, setScroll] = useState<string>("");

    useEffect(() => {
        const container = document.getElementById("app-content");

        if (container) {
            container.addEventListener("scroll", function () {
                if (container.scrollTop > 295) {
                    document
                        .getElementById("mobile-header")!
                        .classList.add("scrolling2");
                } else if (container.scrollTop > 40) {
                    document
                        .getElementById("mobile-header")!
                        .classList.remove("scrolling2");
                } else {
                    document
                        .getElementById("mobile-header")!
                        .classList.remove("scrolling2");
                    document
                        .getElementById("mobile-header")!
                        .classList.remove("scrolling");
                }
            });
        }
    }, []);

    useEffect(() => {
        getLoyalty();
        setScroll("");
    }, [location.pathname]);

    if (!user) {
        // Render a fallback UI if the user is not defined
        return null;
    }
    const isUserEuropean = UtilsService.IsUserEuropean(user);

    return (
        <div className="mobile-menu !fixed md:hidden" id="id-mobile--menu">
            <div
                className={`!fixed flex justify-between h-[66px] !top-0 items-center !w-full -mr-3.5 right-[14px] px-2`}
                id="mobile-header"
            >
                <div className="w-[70%]">
                    <NavLink to="/" className="text-left">
                        <img
                            alt="Image"
                            className="w-[70%]"
                            src={UtilsService.getPulicImage(
                                "/dist/image/Logo-Infinexia.webp",
                            )}
                        />
                    </NavLink>
                </div>
                <div className="w-[30%]">
                    <div className="flex items-center w-full justify-end">
                        <div className="w-10 h-10 mt-2 notif">
                            <NotificationInformation />
                        </div>
                        <div className="w-10 h-10 mt-2 notif">
                            <Notification />
                        </div>
                        <div className="w-10 h-10 mt-2 profil">
                            <Dropdown />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="scrollable overflow-y-auto h-screen bg-gray-200"
                id="sidebar"
            >
                <NavLink
                    to={``}
                    className="mobile-menu-toggler"
                    onClick={(e) => toggleExanded(e)}
                >
                    <CloseMenuSvg />
                </NavLink>
                <ul className="scrollable__content py-2 relative">
                    <div className="my-2 ml-2 text-white intro-x ">
                        {translate("Side", "Manage")}
                    </div>
                    <div className="side-nav__devider mdg-side-profile my-6"></div>
                    {AccountMenu.map((menu, i) => (
                        <ItemListMobile
                            key={i}
                            name={menu.name}
                            icon={menu.icon}
                            path={menu.path}
                            childs={menu.childs}
                        />
                    ))}
                    <li className="menu__devider my-1"></li>
                    <div className="my-2 ml-2 text-white intro-x">
                        {translate("Side", "Finances")}
                    </div>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/savings-plans",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <RankSvg />
                            </div>
                            <div className="menu__title">
                                {translate("Services", "Services")}
                            </div>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/cryptos",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <CryptosSvg />
                            </div>
                            <div className="menu__title">
                                {translate("Wallets", "Cryptos")}
                            </div>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    currentLoyalty
                                        ? //  &&
                                          //     (currentLoyalty.name === "Blue" ||
                                          //         currentLoyalty.name === "Gold" ||
                                          //         currentLoyalty.name === "Black")
                                          "/cards"
                                        : "/subscription",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-credit-card"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
                                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"></path>
                                </svg>
                            </div>
                            <div className="menu__title">
                                {translate("Admin_Affiliation", "Loyalty")}
                            </div>
                        </button>
                    </li>
                    {(isCompany || isAdmin) &&
                        !!currentLoyalty?.quotes_invoices && (
                            <li>
                                <button
                                    type="button"
                                    className="bg-transparent border-none menu single-menu cursor-default"
                                    onClick={() =>
                                        hangleCLick(
                                            "/estimates-invoices/estimates",
                                            "mobile-menu !fixed md:hidden",
                                        )
                                    }
                                >
                                    <div className="menu__icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22"
                                            height="22"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="lucide lucide-receipt-text mr-0.5"
                                        >
                                            <path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z" />
                                            <path d="M14 8H8" />
                                            <path d="M16 12H8" />
                                            <path d="M13 16H8" />
                                        </svg>
                                    </div>
                                    <div className="menu__title">
                                        {translate(
                                            "ESTIMATES_INVOICES",
                                            "Estimates_Invoices",
                                        )}
                                    </div>
                                </button>
                            </li>
                        )}
                    {user &&
                        user.merchant_app_id &&
                        user.merchant_app_key &&
                        !!currentLoyalty?.infinexia_api && (
                            <li>
                                <button
                                    type="button"
                                    className="bg-transparent border-none menu single-menu cursor-default"
                                    onClick={() =>
                                        hangleCLick(
                                            "/api-infinexia",
                                            "mobile-menu !fixed md:hidden",
                                        )
                                    }
                                >
                                    <div className="menu__icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="23"
                                            height="23"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="lucide lucide-hand-coins rotate-[20deg]"
                                        >
                                            <path d="M11 15h2a2 2 0 1 0 0-4h-3c-.6 0-1.1.2-1.4.6L3 17" />
                                            <path d="m7 21 1.6-1.4c.3-.4.8-.6 1.4-.6h4c1.1 0 2.1-.4 2.8-1.2l4.6-4.4a2 2 0 0 0-2.75-2.91l-4.2 3.9" />
                                            <path d="m2 16 6 6" />
                                            <circle cx="16" cy="9" r="2.9" />
                                            <circle cx="6" cy="5" r="3" />
                                        </svg>
                                    </div>
                                    <div className="menu__title">
                                        {translate(
                                            "ACCOUNTING",
                                            "Infinexia_Api",
                                        )}
                                    </div>
                                </button>
                            </li>
                        )}
                    {/* START ADVISOR SIDE */}
                    {(isAdvisor ||
                        isAdminFi ||
                        isAdmin ||
                        isAdmin_n2 ||
                        isSuperAdmin) && (
                        <li className="menu__devider my-1"></li>
                    )}
                    {!isAdminFi &&
                        !isAdmin &&
                        !isAdmin_n2 &&
                        !isSuperAdmin &&
                        isAdvisor && (
                            <div className="my-2 ml-2 text-white intro-x">
                                {translate("ADVISOR", "Advisor")}
                            </div>
                        )}
                    {!isAdvisor && !isAdmin && !isAdmin_n2 && isAdminFi && (
                        <div className="my-2 ml-2 text-white intro-x">
                            {translate("ADVISOR", "Admin Fi")}
                        </div>
                    )}
                    {!isAdvisor &&
                        !isAdminFi &&
                        (isAdmin || isAdmin_n2 || isSuperAdmin) && (
                            <div className="my-2 ml-2 text-white intro-x">
                                {translate("ADVISOR", "Administrateur")}
                            </div>
                        )}
                    {(isSuperAdmin || isAdminFi || isAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/general-report",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="lucide lucide-file-chart-pie"
                                    >
                                        <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                                        <path d="M16 22h2a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v3.5" />
                                        <path d="M4.017 11.512a6 6 0 1 0 8.466 8.475" />
                                        <path d="M9 16a1 1 0 0 1-1-1v-4c0-.552.45-1.008.995-.917a6 6 0 0 1 4.922 4.922c.091.544-.365.995-.917.995z" />
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate(
                                        "Admin_Dashboard",
                                        "General_Report",
                                    )}
                                </div>
                            </button>
                        </li>
                    )}
                    {(isSuperAdmin || isAdminFi || isAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/financial-accounting",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-wallet"
                                    >
                                        {" "}
                                        <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />{" "}
                                        <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />{" "}
                                        <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />{" "}
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate("Admin_Dashboard", "Compta")}
                                </div>
                            </button>
                        </li>
                    )}
                    {(isAdvisor ||
                        isAdminFi ||
                        isAdmin ||
                        isAdmin_n2 ||
                        isSuperAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/user",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        icon-name="users"
                                        data-lucide="users"
                                        className="lucide lucide-users"
                                    >
                                        <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                                        <circle cx="9" cy="7" r="4"></circle>
                                        <path d="M23 21v-2a4 4 0 00-3-3.87"></path>
                                        <path d="M16 3.13a4 4 0 010 7.75"></path>
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate("Moderation", "Customer")}
                                </div>
                            </button>
                        </li>
                    )}
                    {(isAdvisor ||
                        isAdminFi ||
                        isAdmin ||
                        isAdmin_n2 ||
                        isSuperAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/all-transactions",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="currentColor"
                                        className="bi bi-card-list"
                                        viewBox="0 0 16 16"
                                    >
                                        {" "}
                                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                                        <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate("Wallets", "All_Transactions")}
                                </div>
                                {transactionCount && (
                                    <div className="inline-flex items-center justify-center w-6 h-6 text-xs bg-perfect-light rounded-full top-3 right-2 dark:border-gray-900">
                                        {getCountValue("/all-transactions")}
                                    </div>
                                )}
                            </button>
                        </li>
                    )}
                    {(isAdminFi || isAdmin || isSuperAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/deposit-request",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        icon-name="log-in"
                                        data-lucide="log-in"
                                        className="lucide lucide-log-in block mx-auto rotate-90"
                                    >
                                        <path d="M15 3h4a2 2 0 012 2v14a2 2 0 01-2 2h-4"></path>
                                        <polyline points="10 17 15 12 10 7"></polyline>
                                        <line
                                            x1="15"
                                            y1="12"
                                            x2="3"
                                            y2="12"
                                        ></line>
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate("Deposit", "Incoming_transfers")}
                                </div>
                                {transactionCount && (
                                    <div className="inline-flex items-center justify-center w-6 h-6 text-xs bg-perfect-light rounded-full top-3 right-2 dark:border-gray-900">
                                        {getCountValue("/deposit-request")}
                                    </div>
                                )}
                            </button>
                        </li>
                    )}
                    {(isAdminFi || isAdmin || isSuperAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/withdrawal-request",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <FramerSvg />
                                </div>
                                <div className="menu__title">
                                    {translate(
                                        "Admin_Withdrawal_Request",
                                        "Withdrawal_Request",
                                    )}
                                </div>
                                {transactionCount && (
                                    <div className="inline-flex items-center justify-center w-6 h-6 text-xs bg-perfect-light rounded-full top-3 right-2 dark:border-gray-900">
                                        {getCountValue("/withdrawal-request")}
                                    </div>
                                )}
                            </button>
                        </li>
                    )}
                    {(isAdvisor ||
                        isAdminFi ||
                        isAdmin ||
                        isAdmin_n2 ||
                        isSuperAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/moderation",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        icon-name="list"
                                        data-lucide="list"
                                        className="lucide lucide-list block mx-auto"
                                    >
                                        <line
                                            x1="8"
                                            y1="6"
                                            x2="21"
                                            y2="6"
                                        ></line>
                                        <line
                                            x1="8"
                                            y1="12"
                                            x2="21"
                                            y2="12"
                                        ></line>
                                        <line
                                            x1="8"
                                            y1="18"
                                            x2="21"
                                            y2="18"
                                        ></line>
                                        <line
                                            x1="3"
                                            y1="6"
                                            x2="3.01"
                                            y2="6"
                                        ></line>
                                        <line
                                            x1="3"
                                            y1="12"
                                            x2="3.01"
                                            y2="12"
                                        ></line>
                                        <line
                                            x1="3"
                                            y1="18"
                                            x2="3.01"
                                            y2="18"
                                        ></line>
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate("Moderation", "Moderation")}
                                </div>
                                {transactionCount && (
                                    <div className="inline-flex items-center justify-center w-6 h-6 text-xs bg-perfect-light rounded-full top-3 right-2 dark:border-gray-900">
                                        {getCountValue("/moderation")}
                                    </div>
                                )}
                            </button>
                        </li>
                    )}
                    {(isAdvisor ||
                        isAdminFi ||
                        // isAdmin ||
                        isAdmin_n2 ||
                        isSuperAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/api-access-request",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        icon-name="list"
                                        data-lucide="list"
                                        className="lucide lucide-list block mx-auto"
                                    >
                                        <line
                                            x1="8"
                                            y1="6"
                                            x2="21"
                                            y2="6"
                                        ></line>
                                        <line
                                            x1="8"
                                            y1="12"
                                            x2="21"
                                            y2="12"
                                        ></line>
                                        <line
                                            x1="8"
                                            y1="18"
                                            x2="21"
                                            y2="18"
                                        ></line>
                                        <line
                                            x1="3"
                                            y1="6"
                                            x2="3.01"
                                            y2="6"
                                        ></line>
                                        <line
                                            x1="3"
                                            y1="12"
                                            x2="3.01"
                                            y2="12"
                                        ></line>
                                        <line
                                            x1="3"
                                            y1="18"
                                            x2="3.01"
                                            y2="18"
                                        ></line>
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate("ROLES", "API_ACCESS")}
                                </div>
                                {transactionCount && (
                                    <div className="inline-flex items-center justify-center w-6 h-6 text-xs bg-perfect-light rounded-full top-3 right-2 dark:border-gray-900">
                                        {getCountValue("/api-access-request")}
                                    </div>
                                )}
                            </button>
                        </li>
                    )}
                    {(isAdvisor ||
                        isAdminFi ||
                        isAdmin ||
                        isAdmin_n2 ||
                        isSuperAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/subscriptions-orders-list",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="lucide lucide-receipt-text mr-0.5"
                                    >
                                        <path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z" />
                                        <path d="M14 8H8" />
                                        <path d="M16 12H8" />
                                        <path d="M13 16H8" />
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate(
                                        "SUBSCRIPTIONS",
                                        "Loyalty_list2",
                                    )}
                                </div>
                                {transactionCount && (
                                    <div className="inline-flex items-center justify-center w-6 h-6 text-xs bg-perfect-light rounded-full top-3 right-2 dark:border-gray-900">
                                        {getCountValue(
                                            "/subscriptions-orders-list",
                                        )}
                                    </div>
                                )}
                            </button>
                        </li>
                    )}
                    {(isAdvisor ||
                        isAdminFi ||
                        isAdmin ||
                        isAdmin_n2 ||
                        isSuperAdmin) && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/order-card",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="currentColor"
                                        className="bi bi-credit-card"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
                                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"></path>
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate("ROLES", "CARDS_ORDERS")}
                                </div>
                                {transactionCount && (
                                    <div className="inline-flex items-center justify-center w-6 h-6 text-xs bg-perfect-light rounded-full top-3 right-2 dark:border-gray-900">
                                        {getCountValue("/order-card")}
                                    </div>
                                )}
                            </button>
                        </li>
                    )}
                    {/* END ADVISOR SIDE */}
                    <li className="menu__devider my-1"></li>
                    <div className="my-2 ml-2 text-white intro-x">
                        {translate("Side", "Supports")}
                    </div>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/settings",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="settings"
                                    data-lucide="settings"
                                    className="lucide lucide-settings"
                                >
                                    <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            </div>
                            <div className="menu__title">
                                {translate("Profil", "Setting_Security")}
                            </div>
                        </button>
                    </li>
                    {!is_minor && (
                        <li>
                            <button
                                type="button"
                                className="bg-transparent border-none menu single-menu cursor-default"
                                onClick={() =>
                                    hangleCLick(
                                        "/referrals",
                                        "mobile-menu !fixed md:hidden",
                                    )
                                }
                            >
                                <div className="menu__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 256 256"
                                        strokeWidth="13"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        icon-name="referral"
                                        className="referral mr-[-4px]"
                                        shapeRendering="geometricPrecision"
                                        textRendering="geometricPrecision"
                                    >
                                        <rect
                                            width="256"
                                            height="256"
                                            rx="0"
                                            ry="0"
                                            fill="none"
                                        />
                                        <g transform="matrix(1.245876 0 0 1.229136-31.472128-33.070446)"></g>
                                        <circle
                                            r="40"
                                            transform="translate(128 140)"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="13"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M196,116c18.892886-.031607,36.689554,8.866728,48,24"
                                            transform="translate(-22.955626 20.656283)"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="13"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M12,140c11.310446-15.133272,29.107114-24.031607,48-24"
                                            transform="translate(22.955625 20.656283)"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="13"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M70.4,216c10.748603-22.01266,33.103272-35.974389,57.6-35.974389s46.851397,13.961729,57.6,35.974389"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="13"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M60,116c-16.896593.017142-30.894838-13.1053-31.967871-29.967796s11.148961-31.653444,27.911439-33.778174s32.28831,9.149038,35.456432,25.74597"
                                            transform="translate(22.955625 20.656283)"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="13"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M164.6,78c3.168122-16.596932,18.693953-27.870701,35.456432-25.74597s28.984473,16.915679,27.911439,33.778174-15.071278,29.984938-31.967871,29.967796"
                                            transform="translate(-22.955626 20.656283)"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="13"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className="menu__title">
                                    {translate("Profil", "Affiliation")}
                                </div>
                            </button>
                        </li>
                    )}
                    {(isAdmin || isSuperAdmin) && (
                        <>
                            <li>
                                <button
                                    type="button"
                                    className="bg-transparent border-none menu single-menu cursor-default"
                                    onClick={() =>
                                        hangleCLick(
                                            isSuperAdmin
                                                ? "/admin/dashboard"
                                                : "/admin/user",
                                            // "/admin/dashboard",
                                            "mobile-menu !fixed md:hidden",
                                        )
                                    }
                                >
                                    <div className="menu__icon">
                                        <UserRoundCogDropdownSvg />
                                    </div>
                                    <div className="menu__title">
                                        {translate("Head", "Admin")}
                                    </div>
                                </button>
                            </li>
                        </>
                    )}
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/logout",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <LogoutSidePcSvg />
                            </div>
                            <div className="menu__title">
                                {translate("Head", "Logout")}
                            </div>
                        </button>
                    </li>
                </ul>
            </div>
            <div className="!bg-black !fixed !bottom-0 !top-auto !w-full -mr-3.5 right-[14px]">
                <div className="bg-black w-full py-0 px-3 text-white flex items-center space-x-2">
                    <div className="w-1/5">
                        <NavLink
                            to="/dashboard"
                            onClick={incrementAction}
                            className="block text-[10px] text-center leading-3 cursor-default"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="monitor"
                                data-lucide="monitor"
                                className="lucide lucide-monitor m-auto mb-2"
                            >
                                <rect
                                    x="2"
                                    y="3"
                                    width="20"
                                    height="14"
                                    rx="2"
                                    ry="2"
                                ></rect>
                                <line x1="8" y1="21" x2="16" y2="21"></line>
                                <line x1="12" y1="17" x2="12" y2="21"></line>
                            </svg>
                            <div className="menu__title">
                                {translate("Dashboard", "Dashboard")}
                            </div>
                        </NavLink>
                    </div>
                    <div className="w-1/5">
                        <NavLink
                            to="/accounts"
                            onClick={incrementAction}
                            className="block text-[10px] text-center leading-3 cursor-default"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="60"
                                height="32"
                                viewBox="0 0 22 28"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-wallet"
                            >
                                <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />
                                <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />
                                <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />
                            </svg>
                            <div className="menu__title">
                                {translate("Wallets", "My_Wallets")}
                            </div>
                        </NavLink>
                    </div>
                    <div className="w-1/5">
                        <NavLink
                            to={``}
                            onClick={(e) => {
                                incrementAction();
                                toggleExanded(e);
                            }}
                            className="block text-[10px] text-center cursor-default !right-auto !relative !top-auto mobile-menu-toggler bottom-[10px] bg-black rounded-[50%] p-[10px] !z-[9999]"
                        >
                            <img
                                src={UtilsService.getPulicImage(
                                    "/dist/image/Logo_Fav_XIA.webp",
                                )}
                                className="w-[50px] m-auto"
                                alt=""
                            />
                        </NavLink>
                    </div>
                    <div className="w-1/5">
                        <NavLink
                            to="/savings-plans"
                            onClick={incrementAction}
                            className="block text-[10px] text-center leading-3 cursor-default"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="pie-chart"
                                data-lucide="pie-chart"
                                className="lucide lucide-pie-chart block m-auto mb-2"
                            >
                                <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                                <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                            </svg>
                            <div className="menu__title">
                                {translate("My_savings", "Saving_mobile")}
                            </div>
                        </NavLink>
                    </div>
                    <div className="w-1/5">
                        <NavLink
                            to="/cryptos"
                            onClick={incrementAction}
                            className="block text-[10px] text-center leading-3 cursor-default"
                        >
                            <CryptosDownMenuSvg />
                            <div className="menu__title">
                                {translate("Wallets", "Cryptos")}
                            </div>
                        </NavLink>
                    </div>
                    {/*
                    <div className="w-1/5">
                        {isEuropean ? (
                            <NavLink
                                to={
                                    currentLoyalty &&
                                    (currentLoyalty.name === "Blue" ||
                                        currentLoyalty.name === "Gold" ||
                                        currentLoyalty.name === "Black")
                                        ? "/cards"
                                        : "/subscription"
                                }
                                onClick={incrementAction}
                                className="block text-[10px] text-center leading-3 cursor-default"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-credit-card m-auto mb-2"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
                                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"></path>
                                </svg>
                                <div className="menu__title">
                                    {translate("Side", "Cards")}
                                </div>
                            </NavLink>
                        ) : (
                            <NavLink
                                to="/transactions"
                                onClick={incrementAction}
                                className="block text-[10px] text-center leading-3 cursor-default"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-card-list m-auto mb-2"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                </svg>
                                <div className="menu__title">
                                    {translate("Wallets", "My_Transactions")}
                                </div>
                            </NavLink>
                        )}
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default SideMobile;
