import PnlModel, {
    ActifRepartition,
    PnlAnalysisModel,
    TermModel,
} from "../../Models/Pnl";
import { UserCryptoWallet } from "../../Models/UserWallet";
import axiosApiInstance from "../AxiosInstance";
import UtilsService from "../UtilsService";

const mapPnl = (elt: any): PnlModel => {
    return new PnlModel(
        elt.available,
        elt.blocked,
        elt.solde_fiat,
        elt.solde_crypto,
        elt.withdrawn,
        elt.solde_principal,
        elt.solde_coffre,
        elt.solde_cagnotte,
        elt.data,
        elt.pnls,
    );
};

const toPnlAnalysis = (param: any): PnlAnalysisModel => {
    const mapped: PnlAnalysisModel = {
        logo: param.logo,
        abbreviation: param.abbreviation,
        solde: param.solde,
        withdraw_swap: param.withdraw_swap,
        invested: param.invested,
        user_wallet_id: param.user_wallet_id,
    };
    return mapped;
};

const getPnl = async (): Promise<PnlModel> => {
    return axiosApiInstance
        .get(`/pnl`)
        .then(({ data }) => {
            const res: PnlModel = mapPnl(data);
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getPnlGlobal = async (): Promise<PnlModel> => {
    return axiosApiInstance
        .get(`/pnl/pnl-global`)
        .then(({ data }) => {
            const res: PnlModel = mapPnl(data);
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGains = async (): Promise<{ crypto: number; fiat: number }> => {
    return axiosApiInstance
        .get(`/pnl/gains`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getRepartition = async (): Promise<ActifRepartition> => {
    return axiosApiInstance
        .get(`/pnl/repartition`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getRepartitionGlobal = async (): Promise<ActifRepartition> => {
    return axiosApiInstance
        .get(`/pnl/repartition-global`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getPnlAdmin = async (uid: string): Promise<PnlModel> => {
    return axiosApiInstance
        .get(`/pnl/pnl-data/${uid}`)
        .then(({ data }) => {
            const res: PnlModel = mapPnl(data);
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getYesterdayPnlData = async (): Promise<PnlModel> => {
    return axiosApiInstance
        .get(`/pnl/yesterday`)
        .then(({ data }) => {
            const res: PnlModel = mapPnl({
                available: data.available,
                blocked: data.blocked,
                solde_crypto: data.sold_crypto,
                solde_fiat: data.sold_fiat,
                withdrawn: data.withdrawn,
            });
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getYesterdayPnlDataAdmin = async (uid: string): Promise<PnlModel> => {
    return axiosApiInstance
        .get(`/pnl/yesterday/${uid}`)
        .then(({ data }) => {
            const res: PnlModel = mapPnl({
                available: data.available,
                blocked: data.blocked,
                solde_crypto: data.sold_crypto,
                solde_fiat: data.sold_fiat,
                withdrawn: data.withdrawn,
            });
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getDailyEarn = async (): Promise<any> => {
    return axiosApiInstance
        .get(`/wallet-history/get-daily-earn`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getFiatDailyEarn = async (): Promise<any> => {
    return axiosApiInstance
        .get(`/wallet-history/get-daily-earn`)
        .then(({ data }) => {
            // Filtrer les résultats pour ne récupérer que les gains des fiat
            const fiatAssets = data.assets.filter(
                (asset: UserCryptoWallet) => asset.type === "fiat",
            );

            // Calculer les gains journaliers fiat
            const totalFiatDailyEarn = fiatAssets.reduce(
                (total: number, asset: UserCryptoWallet) =>
                    total + asset.daily_earn,
                0,
            );

            return {
                total: totalFiatDailyEarn,
                assets: fiatAssets, // Retourne aussi les assets fiat si nécessaire
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getCryptoDailyEarn = async (): Promise<any> => {
    return axiosApiInstance
        .get(`/wallet-history/get-daily-earn`)
        .then(({ data }) => {
            // Filtrer les résultats pour ne récupérer que les gains des cryptos
            const cryptoAssets = data.assets.filter(
                (asset: UserCryptoWallet) => asset.type === "crypto",
            );

            // Calculer les gains journaliers crypto
            const totalCryptoDailyEarn = cryptoAssets.reduce(
                (total: number, asset: UserCryptoWallet) =>
                    total + asset.daily_earn,
                0,
            );

            return {
                total: totalCryptoDailyEarn,
                assets: cryptoAssets, // Retourne aussi les assets crypto si nécessaire
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getDailyEarnAdmin = async (uid: string): Promise<any> => {
    return axiosApiInstance
        .get(`/wallet-history/get-daily-earn/${uid}`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getLastMonthData = async (): Promise<PnlModel> => {
    return axiosApiInstance
        .get(`/pnl/last-month`)
        .then(({ data }) => {
            const res: PnlModel = mapPnl({
                available: data.pl_available,
                blocked: data.pl_blocked,
                solde_crypto: data.pl_sold_crypto,
                solde_fiat: data.pl_sold_fiat,
                withdrawn: data.pl_withdrawn,
            });
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getByTermPnlData = async (): Promise<TermModel> => {
    return axiosApiInstance
        .get(`/pnl/data-by-term`)
        .then(({ data }) => {
            return data as TermModel;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getPnlAnalysis = async (): Promise<Array<PnlAnalysisModel>> => {
    return axiosApiInstance
        .get(`/pnl/pnl-analysis`)
        .then(({ data }) => {
            const res: Array<PnlAnalysisModel> = data.map(
                (val: PnlAnalysisModel) => toPnlAnalysis(val),
            );
            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const PnlService = {
    getPnl,
    getYesterdayPnlData,
    getByTermPnlData,
    getPnlAnalysis,
    getLastMonthData,
    getDailyEarn,
    getPnlAdmin,
    getDailyEarnAdmin,
    getYesterdayPnlDataAdmin,
    getRepartition,
    getRepartitionGlobal,
    getFiatDailyEarn,
    getCryptoDailyEarn,
    getGains,
    getPnlGlobal,
};

export default PnlService;
