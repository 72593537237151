import React, { useEffect, useMemo, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import translator from "../../../Components/Extra/Translation/Translate";
import alert_message from "../../../Services/Alert/AlertMessage";
import ComptabilityService from "../../../Services/Comptability/Comptability";
import CardInfo from "../Dashboard/CardInfo";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import { TotalFluxAndFees } from "../../../Models/Comptability";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);
export interface Raport {
    cb: 0;
    sum_amount_kalypay: 0;
    sum_fidelity: 0;
    sum_withdrawal: 0;
    fee_swap: 0;
    swap: 0;
    sum_commission: 0;
    count_user: {
        total: 0;
        active: 0;
    };
}
const AdminFluxFeesAccounting: React.FC = () => {
    const { base: baseCurrency } = useBaseCurrency();
    const { translate } = translator();

    const [fluxFees, setFluxFees] = useState<TotalFluxAndFees>();

    const getTotalFluxAndFees = async () => {
        try {
            const response = await ComptabilityService.getTotalFluxAndFees();
            setFluxFees(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };
    useEffect(() => {
        getTotalFluxAndFees();
    }, []);
    return (
        <div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="intro-y mt-10 items-center">
                                <h2 className="mr-auto text-lg font-medium !capitalize">
                                    {translate("ACCOUNTING", "Flux")}
                                </h2>
                            </div>
                            <div className="mt-2 grid grid-cols-12 gap-6">
                                {fluxFees && baseCurrency && (
                                    <>
                                        <CardInfo
                                            data={fluxFees.deposit}
                                            label={translate(
                                                "All_Transactions_Admin",
                                                "Deposits",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={fluxFees.exchange}
                                            label={translate(
                                                "All_Transactions_Admin",
                                                "Swap",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={fluxFees.retrait}
                                            label={translate(
                                                "All_Transactions_Admin",
                                                "Withdrawals",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={fluxFees.paiements_marchand}
                                            label={translate(
                                                "ACCOUNTING",
                                                "Payment_Merchant",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={fluxFees.rechargement_cb}
                                            label={translate(
                                                "ACCOUNTING",
                                                "CB_Topup",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={fluxFees.achats_crypto}
                                            label={translate(
                                                "ACCOUNTING",
                                                "Buy_Crypto",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfo
                                            data={fluxFees?.gateway_payeer}
                                            label={translate(
                                                "ACCOUNTING",
                                                "Gateway_Payeer",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />

                                        <CardInfo
                                            data={fluxFees.gateway_cb}
                                            label={translate(
                                                "ACCOUNTING",
                                                "Gateway_CB",
                                            )}
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminFluxFeesAccounting;
