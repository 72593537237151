import { useEffect, useMemo } from "react";
import { RoleName } from "../../../Models/UserRole";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import PageWithRoleProtection from "../../Layout/ProtectionRole/WithProtectionRole";
import DashboardAdmin from "./DashboardAdmin";
import React from "react";

const RaporGeneralProtect: React.FC = () => {
    const ProtectedComponentWithRole = PageWithRoleProtection();
    const { user } = useAuthentication();
    const roles = user?.scopes;
    const allowedRoles = [
        RoleName.ADMIN,
        RoleName.ADMIN_FINANCE,
        RoleName.SUPER_ADMIN,
    ];
    return (
        <ProtectedComponentWithRole
            userRoles={roles}
            allowedRoles={allowedRoles}
            pageProtege={DashboardAdmin}
        ></ProtectedComponentWithRole>
    );
};

export default RaporGeneralProtect;
