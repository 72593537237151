import React, { useEffect, useMemo, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import WalletService from "../../../Services/Wallets/WalletService";
import alert_message from "../../../Services/Alert/AlertMessage";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import { UserCryptoWallet } from "../../../Models/UserWallet";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);

const AdminWalletsAccounting: React.FC = () => {
    const { base: baseCurrency } = useBaseCurrency();

    const { translate } = translator();
    const [totalCryptoSolde, setTotalCryptoSolde] = useState<number>(0);
    const [totalFiatSolde, setTotalFiatSolde] = useState<number>(0);
    const [totalInvestedCrypto, setTotalInvestedCrypto] = useState<number>(0);
    const [totalInvestedFiat, setTotalInvestedFiat] = useState<number>(0);
    const [totalCoffre, setTotalCoffre] = useState<number>(0);
    const [totalCagnotte, setTotalCagnotte] = useState<number>(0);
    const [totalSubAccount, setTotalSubAccount] = useState<number>(0);

    const getUserCryptoWallets = async () => {
        try {
            const response =
                await WalletService.getAllCryptoWithAllUserWallet();

            const crypto_wallets: UserCryptoWallet[] = [];
            const fiat_wallets: UserCryptoWallet[] = [];

            for (const wallet of response.user_wallets) {
                if (wallet.type === "crypto") {
                    crypto_wallets.push(wallet);
                } else {
                    fiat_wallets.push(wallet);
                }
            }

            setTotalCryptoSolde(getTotalSolde(crypto_wallets));
            setTotalInvestedCrypto(getTotalInvestedCrypto(crypto_wallets));
            setTotalFiatSolde(getTotalSolde(fiat_wallets));
            setTotalInvestedFiat(getTotalInvestedCrypto(fiat_wallets));
            // setUserFiatWallets(fiat_wallets);
            setTotalCoffre(response?.coffre || 0);
            setTotalCagnotte(response?.cagnotte || 0);
            setTotalSubAccount(response?.sub_account || 0);
        } catch (err: any) {
            alert_message.error(err);
        }
    };
    const getTotalSolde = (data: UserCryptoWallet[]): number => {
        return data.reduce(
            (sommeTotale, item) =>
                sommeTotale + item.current_price * item.wallet.solde,
            0,
        );
    };

    const getTotalInvestedCrypto = (data: UserCryptoWallet[]): number => {
        return data.reduce(
            (sommeTotale, item) => sommeTotale + item.wallet.staking_usd_price,
            0,
        );
    };

    useEffect(() => {
        getUserCryptoWallets();
    }, []);
    return (
        <div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="intro-y mt-5 items-center">
                                <h2 className="mr-auto text-lg font-medium">
                                    {translate(
                                        "My_Transactions",
                                        "Total_balances",
                                    )}
                                </h2>
                            </div>
                            <div className="intro-y flex items-center"></div>
                            <div className="mt-5 grid grid-cols-6 gap-6">
                                <div className="intro-y">
                                    <div className="report-box hover:cursor-auto dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-3">
                                            <div className="flex">
                                                <div className="mt-4 text-base ml-2 flex items-center">
                                                    {translate(
                                                        "My_Transactions",
                                                        "Total_balances",
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="mt-10">
                                                    <div className="text-base text-slate-500">
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Solde",
                                                        )}
                                                    </div>
                                                    <div className="mt-1 text-lg font-medium leading-8">
                                                        {baseCurrency
                                                            ? UtilsService.getConvertedFormatedAmount(
                                                                  totalCryptoSolde +
                                                                      totalFiatSolde,
                                                                  baseCurrency,
                                                                  2,
                                                              )
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="intro-y">
                                    <div className="report-box hover:cursor-auto dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-3">
                                            <div className="flex">
                                                <div className="mt-4 text-base ml-2 flex items-center">
                                                    {translate(
                                                        "ACCOUNTING",
                                                        "Fiat_Balances",
                                                    )}
                                                </div>
                                                <div className="ml-auto">
                                                    <div className="report-box__indicator !text-slate-600 tooltip cursor-pointer h-8">
                                                        {translate(
                                                            "My_Transactions",
                                                            "Invested",
                                                        )}
                                                        <br />
                                                        {baseCurrency
                                                            ? UtilsService.getConvertedFormatedAmount(
                                                                  totalInvestedFiat,
                                                                  baseCurrency,
                                                                  2,
                                                              )
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="mt-10">
                                                    <div className="text-base text-slate-500">
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Solde",
                                                        )}
                                                    </div>
                                                    <div className="mt-1 text-lg font-medium leading-8">
                                                        {baseCurrency
                                                            ? UtilsService.getConvertedFormatedAmount(
                                                                  totalFiatSolde,
                                                                  baseCurrency,
                                                                  2,
                                                              )
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="intro-y">
                                    <div className="report-box hover:cursor-auto dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-3">
                                            <div className="flex">
                                                <div className="mt-4 text-base ml-2 flex items-center">
                                                    {translate(
                                                        "ACCOUNTING",
                                                        "Safe_Pots",
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="mt-10">
                                                    <div className="text-base text-slate-500">
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Solde",
                                                        )}
                                                    </div>
                                                    <div className="mt-1 text-lg font-medium leading-8">
                                                        {baseCurrency
                                                            ? UtilsService.getConvertedFormatedAmount(
                                                                  totalCoffre,
                                                                  baseCurrency,
                                                                  2,
                                                              )
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="intro-y">
                                    <div className="report-box hover:cursor-auto dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-3">
                                            <div className="flex">
                                                <div className="mt-4 text-base ml-2 flex items-center">
                                                    {translate(
                                                        "ACCOUNTING",
                                                        "SubAccount_Balance",
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="mt-10">
                                                    <div className="text-base text-slate-500">
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Solde",
                                                        )}
                                                    </div>
                                                    <div className="mt-1 text-lg font-medium leading-8">
                                                        {baseCurrency
                                                            ? UtilsService.getConvertedFormatedAmount(
                                                                  totalSubAccount,
                                                                  baseCurrency,
                                                                  2,
                                                              )
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="intro-y">
                                    <div className="report-box hover:cursor-auto dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-3">
                                            <div className="flex">
                                                <div className="mt-4 text-base ml-2 flex items-center">
                                                    {translate(
                                                        "ACCOUNTING",
                                                        "Pots_Balance",
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="mt-10">
                                                    <div className="text-base text-slate-500">
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Solde",
                                                        )}
                                                    </div>
                                                    <div className="mt-1 text-lg font-medium leading-8">
                                                        {baseCurrency
                                                            ? UtilsService.getConvertedFormatedAmount(
                                                                  totalCagnotte,
                                                                  baseCurrency,
                                                                  2,
                                                              )
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="intro-y">
                                    <div className="report-box hover:cursor-auto dark:border-2 dark:border-slate-700 dark:rounded-md">
                                        <div className="box p-3">
                                            <div className="flex">
                                                <div className="mt-4 text-base ml-2 flex items-center">
                                                    {translate(
                                                        "My_Transactions",
                                                        "Crypto_balances",
                                                    )}
                                                </div>
                                                <div className="ml-auto">
                                                    <div className="report-box__indicator !text-slate-600 tooltip cursor-pointer h-8">
                                                        {translate(
                                                            "My_Transactions",
                                                            "Invested",
                                                        )}
                                                        <br />
                                                        {baseCurrency
                                                            ? UtilsService.getConvertedFormatedAmount(
                                                                  totalInvestedCrypto,
                                                                  baseCurrency,
                                                                  2,
                                                              )
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex justify-between">
                                                <div className="mt-10">
                                                    <div className="text-base text-slate-500">
                                                        {translate(
                                                            "Wallet_Online",
                                                            "Solde",
                                                        )}
                                                    </div>
                                                    <div className="mt-1 text-lg font-medium leading-8">
                                                        {baseCurrency
                                                            ? UtilsService.getConvertedFormatedAmount(
                                                                  totalCryptoSolde,
                                                                  baseCurrency,
                                                                  2,
                                                              )
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminWalletsAccounting;
