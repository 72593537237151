import { useParams } from "react-router-dom";
import { ThemeSetting, UserType } from "../../Models/User";
import { useAppSelector } from "../../globalState/hook";
export default function useAuthentication() {
    const { child_uid, id } = useParams();
    const { user, isAuthenticated } = useAppSelector((state) => state.auth);
    const isAdmin = user && user.scopes && user.scopes.includes("admin");
    const isMerchant = user && user.scopes && user.scopes.includes("merchant");
    const isJoin =
        user && user.type !== undefined && user.type === UserType.JOIN;
    const isAdvisor = user && user.scopes && user.scopes.includes("advisor");
    const isAdminFi =
        user && user.scopes && user.scopes.includes("admin_finance");
    const isSuperAdmin =
        user && user.scopes && user.scopes.includes("super_admin");
    const isAdmin_n2 = user && user.scopes && user.scopes.includes("admin_n2");

    const getPageBgImage = (
        page:
            | "dashboard"
            | "staking"
            | "wallet"
            | "account18"
            | "userWallet"
            | "cryptos",
    ) => {
        if (!user?.theme_setting) {
            return "Degraded/1.webp";
        }

        const theme_setting = JSON.parse(user.theme_setting) as ThemeSetting;

        switch (page) {
            case "staking":
                return theme_setting.background.staking ?? "Degraded/17.webp";
            case "dashboard":
                return theme_setting.background.dashboard ?? "Degraded/21.webp";
            case "wallet":
                return theme_setting.background.wallet ?? "Degraded/22.webp";
            case "cryptos":
                return theme_setting.background.cryptos ?? "Degraded/18.webp";
            case "account18":
                const data = theme_setting.background.account18;
                const res = Array.isArray(data)
                    ? data.find((elt) => elt.uid === child_uid)
                    : null;
                return res ? res.color : "Degraded/1.webp";

            case "userWallet":
                const bgs = theme_setting.background.userWallet;
                const find = Array.isArray(bgs)
                    ? bgs.find((elt) => elt.id === id)
                    : null;
                return find ? find.color : "Degraded/22.webp";
        }

        return "";
    };

    return {
        isAdmin,
        user,
        isAuthenticated,
        isMerchant,
        isSuperAdmin,
        isJoin,
        isAdvisor,
        isAdminFi,
        isAdmin_n2,
        getPageBgImage,
    };
}
