import React, { useEffect, useRef, useState } from "react";
import LanguageSwitcher from "../../Layouts/Account/Language/Language";
import UtilsService from "../../Services/UtilsService";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import FAQSearch from "./FaqSearch";
import translator from "../../Components/Extra/Translation/Translate";
import { useAppSelector } from "../../globalState/hook";
import { Bars3Icon } from "@heroicons/react/24/outline";
import SidebarLinkSubgroup from "./SidebarLinkSubGroup";
import SidebarLink from "./SidebarLink";
import { useAppProvider } from "../../App/Provider/AppProvider";
import BookSidebarFaqSvg from "../../Components/Extra/Svg/BookSidebarFaqSvg";
import SidebarLinkGroup from "./SidebarLinkGroup";
import BooksFaqSidebarrSvg from "../../Components/Extra/Svg/BooksFaqSidebarrSvg";

const bg = "/dist/image/bg-top.webp";

interface HelpCenterHeaderProps {
    disableDarkMode?: boolean; // Ajout de la prop
}

export default function HelpCenterHeader({
    disableDarkMode,
}: HelpCenterHeaderProps) {
    const location = useLocation();
    const { translate } = translator();
    const { lang } = useParams();
    const { isAuthenticated } = useAppSelector((state) => state.auth);
    const [toggleMenu, setToggleMenu] = useState(false);
    const isHome = true;
    // const isHome = ["/", "/home", "/accueil"].includes(location.pathname);
    const [scroll, setScroll] = useState(isHome);
    const [has_dropdown, setDropdwon] = useState(false);
    const [currentTarget, setCurrentTarget] = useState(
        typeof window != "undefined" ? window.location.hash : "",
    );

    const sidebar = useRef<HTMLDivElement>(null);
    const { sidebarOpen, setSidebarOpen } = useAppProvider();
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const segments = pathname;

    // Gestion de la fermeture de la sidebar
    useEffect(() => {
        const clickHandler = ({ target }: { target: EventTarget | null }) => {
            if (!sidebar.current || sidebar.current.contains(target as Node))
                return;
            setSidebarOpen(false);
        };

        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    }, [sidebarOpen]);

    //Change background header on scroll
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 40 || !isHome)
            setScroll(true); // => scroll position
        else setScroll(false);
    };

    const handleDropdown = () => {
        setDropdwon(!has_dropdown);
    };

    const currentUrl = typeof window != "undefined" ? window.location.href : "";
    const hasBusinessUrl = currentUrl.includes("/business");

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [location.pathname]);

    useEffect(() => {
        const targetElement = document.getElementById(
            currentTarget.replace("#", ""),
        );
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentTarget]);

    return (
        <header className="fixed w-full z-30">
            {/* Background section */}
            <div
                className={`absolute inset-0 md:h-[200px] md:min-h-[200px] md:max-h-[200px] sm:!h-[175px] sm:!max-h-[175px] w-full bg-cover bg-no-repeat flex justify-center items-center text-white border-b border-slate-200 backdrop-blur -z-10 ${
                    disableDarkMode ? "" : "dark:bg-white dark:border-slate-200"
                }`}
                aria-hidden="true"
                style={{ backgroundImage: `url(${bg})` }}
            />

            {/* Main content */}
            <div className="max-w-7xl mx-auto px-2 sm:px-6">
                <div className="flex px-0 lg:!px-0  items-center h-[66px] md:h-full">
                    {/* Logo */}
                    <div className="grow">
                        <NavLink to={lang ? `/${lang}` : "/"}>
                            <img
                                src={UtilsService.getPulicImage(
                                    "/dist/image/Logo-Infinexia.webp",
                                )}
                                className="sm:h-[39px] md:h-[50px] w-auto"
                                alt=""
                            />
                        </NavLink>
                    </div>
                    {/* Two button */}
                    {!isAuthenticated ? (
                        <div className="hidden md:flex xl:ml-20 gap-3 py-5 text-base font-medium">
                            <NavLink
                                to={lang ? `/${lang}/login` : "/login"}
                                className="btn btn-perfect text-white border !border-white px-4 rounded-lg ifx-bouton py-1.5 my-auto mt-1"
                            >
                                {translate("HOME_PAGE", "Sign_In")}
                            </NavLink>

                            <NavLink
                                to={lang ? `/${lang}/register` : "/register"}
                                className="text-white border !border-white px-4 rounded-lg ifx-bouton-bw py-1.5 my-auto mt-1"
                            >
                                {translate("HOME_PAGE", "Create_An_Account")}
                            </NavLink>
                        </div>
                    ) : (
                        <div className="hidden md:flex xl:ml-20 gap-3 py-5 text-base font-medium">
                            <NavLink
                                to="/dashboard"
                                className="btn btn-perfect text-white border !border-white px-4 rounded-lg ifx-bouton py-1.5 my-auto mt-1"
                            >
                                {translate("HOME_PAGE", "My_Account")}
                            </NavLink>
                        </div>
                    )}
                    {/* Language switcher */}
                    <div className="md:block xl:ml-20 flex gap-3 py-5">
                        <div className="hidden md:block intro-none  w-8 h-6 pt-1 text-white font-bold language">
                            <LanguageSwitcher doc />
                        </div>
                    </div>

                    <div className=" xl:hidden  text-black flex justify-end ">
                        <div
                            className={`${
                                scroll ? " text-black" : "text-white"
                            } intro-none w-8 h-6 mt-[4px] md:!mt-[2px] mr-3 sm:mr-6 language `}
                        >
                            <LanguageSwitcher doc />
                        </div>
                        <button
                            onClick={() => setToggleMenu(!toggleMenu)}
                            aria-label="Toggle"
                        >
                            <Bars3Icon
                                className={`h-8 w-8 mr-2 ${
                                    scroll ? " text-white" : "text-white"
                                } `}
                            />
                        </button>
                    </div>
                </div>
            </div>
            <div
                className={`text-black text-lg fixed z-40 w-full  bg-gray-100 overflow-y-auto flex text-center items-center flex-col xl:hidden gap-12  origin-top duration-700 ${
                    !toggleMenu ? "h-0" : "h-full pb-28"
                }`}
            >
                <div className="py-0 w-full pb-28">
                    <ul className="flex justify-center items-center flex-col gap-3 font-bold tracking-wider w-full">
                        <nav className="sm:block w-full">
                            {/* 1st level */}
                            <SidebarLinkGroup
                                open={segments.includes("help-center")}
                            >
                                {(handleClick, open) => {
                                    return (
                                        <>
                                            <a
                                                href="#0"
                                                className={`relative flex justify-center items-center text-lg font-[650] text-slate-800 p-1 before:absolute before:inset-0 before:rounded before:bg-gradient-to-tr before:from-blue-400 before:to-purple-500 before:opacity-20 before:-z-10 before:pointer-events-none dark:text-black ${
                                                    !segments.includes(
                                                        "help-center",
                                                    ) && "before:hidden"
                                                }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate(
                                                        "/help-center/intro",
                                                    );
                                                }}
                                            >
                                                <BooksFaqSidebarrSvg />
                                                <span>
                                                    {translate(
                                                        "HELPCENTER",
                                                        "All_Category",
                                                    )}
                                                </span>
                                            </a>
                                        </>
                                    );
                                }}
                            </SidebarLinkGroup>
                            {/* Démarrer avec Infinexia */}
                            <ul className="text-sm text-black dark:text-black pl-20">
                                <SidebarLinkSubgroup
                                    title={translate(
                                        "HELPCENTER",
                                        "Start_Infinexia",
                                    )}
                                    open={segments.includes("getting-start")}
                                >
                                    <SidebarLink
                                        href={`/help-center/getting-start/open-individual-account`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Individual_Account",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/getting-start/open-business-account`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Business_Account",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/getting-start/subscription`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Select_Offer",
                                        )}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                            {/* Comptes */}
                            <ul className="text-sm pl-20">
                                <SidebarLinkSubgroup
                                    title={translate(
                                        "HELPCENTER",
                                        "Accounts_Gestion",
                                    )}
                                    open={segments.includes(
                                        "accounts-management",
                                    )}
                                >
                                    <SidebarLink
                                        href={`/help-center/accounts-management/currencies-accounts`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Currencies_Accounts",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/accounts-management/safes-accounts`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Safes_Accounts",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/accounts-management/jackpots-accounts`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Jackpots_Accounts",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/accounts-management/subaccounts`}
                                    >
                                        {translate("HELPCENTER", "SubAccounts")}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                            {/* Transactions */}
                            <ul className="text-sm pl-20">
                                <SidebarLinkSubgroup
                                    title={translate(
                                        "HELPCENTER",
                                        "Transactions",
                                    )}
                                    open={segments.includes("transactions")}
                                >
                                    <SidebarLink
                                        href={`/help-center/transactions/receive-money`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Receive_Money",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/transactions/send-money`}
                                    >
                                        {translate("HELPCENTER", "Send_Money")}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/transactions/bank-transfers-and-direct-debits`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Bank_Transfers",
                                        )}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                            {/* Cartes bancaires */}
                            <ul className="text-sm pl-20">
                                <SidebarLinkSubgroup
                                    title={translate(
                                        "HELPCENTER",
                                        "Bank_Cards",
                                    )}
                                    open={segments.includes("cards")}
                                >
                                    <SidebarLink
                                        href={`/help-center/cards/physical-cards`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Physical_Cards",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/cards/virtual-cards`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Virtual_Cards",
                                        )}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                            {/* Paramètres & sécurité */}
                            <ul className="text-sm pl-20">
                                <SidebarLinkSubgroup
                                    title={translate(
                                        "HELPCENTER",
                                        "Settings_Security",
                                    )}
                                    open={segments.includes(
                                        "settings-security",
                                    )}
                                >
                                    <SidebarLink
                                        href={`/help-center/settings-security/edit-profile`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Modify_Profile",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/settings-security/login-password`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Login_PassWord",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/settings-security/transaction-password`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Transac_PassWord",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/settings-security/2fa-authentication`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Authentication_2FA",
                                        )}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                            {/* Gagner des récompenses */}
                            <ul className="text-sm pl-20">
                                <SidebarLinkSubgroup
                                    title={translate(
                                        "HELPCENTER",
                                        "Earning_Rewards",
                                    )}
                                    open={segments.includes("rewards")}
                                >
                                    <SidebarLink
                                        href={`/help-center/rewards/infinity-points`}
                                    >
                                        {translate(
                                            "HELPCENTER_REWARDS",
                                            "Infinity_Title",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/rewards/invite-a-friends`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Sponsor_Friends",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink
                                        href={`/help-center/rewards/ambassador`}
                                    >
                                        {translate(
                                            "HELPCENTER",
                                            "Become_Ambassador",
                                        )}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                            {/* Divers */}
                            <ul className="text-sm pl-20">
                                <SidebarLinkSubgroup
                                    title={translate("HELPCENTER", "Various")}
                                    open={segments.includes("various")}
                                >
                                    <SidebarLink href="/help-center/various/contact-customer-service">
                                        {translate(
                                            "HELPCENTER",
                                            "How_Customer_Service",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink href="/help-center/various/working-with-infinexia">
                                        {translate(
                                            "HELPCENTER",
                                            "Working_With_Infinexia",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink href="/help-center/various/press-service">
                                        {translate(
                                            "HELPCENTER",
                                            "Press service",
                                        )}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                            <>
                                <div
                                    className={`mt-3 relative flex justify-center items-center text-lg font-[650] text-slate-800 p-1 before:absolute before:inset-0 before:rounded before:bg-gradient-to-tr before:from-blue-400 before:to-purple-500 before:opacity-20 before:-z-10 before:pointer-events-none dark:text-black ${
                                        !segments.includes("help-center") &&
                                        "before:hidden"
                                    }`}
                                >
                                    <BooksFaqSidebarrSvg />
                                    <span>
                                        {translate(
                                            "HELPCENTER",
                                            "Business_Functionality",
                                        )}
                                    </span>
                                </div>
                            </>
                            {/* Devis / Factures */}
                            <ul className="text-sm pl-20">
                                <SidebarLinkSubgroup
                                    title={translate(
                                        "HELPCENTER",
                                        "Estimates_Invoices",
                                    )}
                                    open={segments.includes(
                                        "estimates-invoices",
                                    )}
                                >
                                    <SidebarLink href="/help-center/estimates-invoices/configure-estimates-invoices">
                                        {translate(
                                            "HELPCENTER",
                                            "Config_Estimates_Invoices",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink href="/help-center/estimates-invoices/create-send-estimates">
                                        {translate(
                                            "HELPCENTER",
                                            "Create_Estimates",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink href="/help-center/estimates-invoices/create-send-invoices">
                                        {translate(
                                            "HELPCENTER",
                                            "Create_Invoices",
                                        )}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                            {/* API de paiement */}
                            <ul className="text-sm pl-20">
                                <SidebarLinkSubgroup
                                    title={translate(
                                        "HELPCENTER",
                                        "Payment_Api",
                                    )}
                                    open={segments.includes("payment-api")}
                                >
                                    <SidebarLink href="/help-center/payment-api/request-api-access">
                                        {translate("HELPCENTER", "Api_Access")}
                                    </SidebarLink>
                                    <SidebarLink href="/help-center/payment-api/api-integration">
                                        {translate(
                                            "HELPCENTER",
                                            "API_Intégration",
                                        )}
                                    </SidebarLink>
                                    <SidebarLink href="/help-center/payment-api/plugin-integration">
                                        {translate("HELPCENTER", "Woo_Plugin")}
                                    </SidebarLink>
                                </SidebarLinkSubgroup>
                            </ul>
                        </nav>
                        {!isAuthenticated ? (
                            <>
                                <li key={"Sign_In"}>
                                    <NavLink
                                        to={`${lang ? `/${lang}` : ""}/login`}
                                        onClick={() =>
                                            setToggleMenu(!toggleMenu)
                                        }
                                        className="mt-5 btn btn-perfect text-white border !border-white px-4 rounded-lg ifx-bouton py-0.5"
                                    >
                                        {translate("HOME_PAGE", "Sign_In")}
                                    </NavLink>
                                </li>
                                <li key={"Create_An_Account"}>
                                    <NavLink
                                        to={`${
                                            lang ? `/${lang}` : ""
                                        }/register`}
                                        onClick={() =>
                                            setToggleMenu(!toggleMenu)
                                        }
                                        className="btn text-white border !border-white px-4 rounded-lg ifx-bouton-bw py-0.5"
                                    >
                                        {translate(
                                            "HOME_PAGE",
                                            "Create_An_Account",
                                        )}
                                    </NavLink>
                                </li>
                            </>
                        ) : (
                            <li key={"My_Account"}>
                                <NavLink
                                    to="/dashboard"
                                    className="mt-5 btn btn-perfect  text-white border !border-white px-4 rounded-lg ifx-bouton py-0.5"
                                >
                                    {translate("HOME_PAGE", "My_Account")}
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            {/* mobile navigation */}

            {/* Centered Help Center and Search Bar */}
            <div className="absolute top-[75px] left-0 right-0 flex flex-col justify-center items-center">
                <h1 className="sm:text-xl md:text-2xl text-white font-bold text-center sm:!mt-2 md:!mt-5">
                    {translate("HELPCENTER", "Help_Center")}
                </h1>
                <div className="mt-0 w-full max-w-xl">
                    <FAQSearch />
                </div>
            </div>
        </header>
    );
}
