import React from "react";
import { Transition } from "@headlessui/react";
import { useEffect, useRef } from "react";
import SidebarLink from "./SidebarLink";
import SidebarLinkSubgroup from "./SidebarLinkSubGroup";
import SidebarLinkGroup from "./SidebarLinkGroup";
import {
    NavLink,
    useLocation,
    useNavigate,
    Link,
    useParams,
} from "react-router-dom";
import { useAppProvider } from "../../App/Provider/AppProvider";
import translator from "../../Components/Extra/Translation/Translate";
import CubeSvg from "../../Components/Extra/Svg/CubeSvg";
import BookSearchFaqSvg from "../../Components/Extra/Svg/BookSearchFaqSvg";
import BookSidebarFaqSvg from "../../Components/Extra/Svg/BookSidebarFaqSvg";
import BooksFaqSidebarrSvg from "../../Components/Extra/Svg/BooksFaqSidebarrSvg";

export default function SupportSidebar({
    onStepChange,
}: {
    onStepChange: (stepId: string) => void;
}) {
    const sidebar = useRef<HTMLDivElement>(null);
    const { sidebarOpen, setSidebarOpen } = useAppProvider();
    const { pathname } = useLocation();
    const { translate } = translator();
    const navigate = useNavigate();
    const { lang } = useParams();

    const segments = pathname;

    // Récupération des segments de l'URL active
    const location = useLocation();

    // Gestion de la fermeture de la sidebar
    useEffect(() => {
        const clickHandler = ({ target }: { target: EventTarget | null }) => {
            if (!sidebar.current || sidebar.current.contains(target as Node))
                return;
            setSidebarOpen(false);
        };

        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    }, [sidebarOpen]);

    return (
        <>
            {/* Backdrop */}
            <Transition
                className="md:hidden absolute inset-0 z-10 bg-slate-900 bg-opacity-20 transition-opacity"
                show={sidebarOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-out duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                aria-hidden="true"
            />

            {/* Sidebar */}
            <div ref={sidebar}>
                <Transition
                    show={sidebarOpen}
                    unmount={false}
                    as="aside"
                    id="sidebar"
                    className="absolute left-0 top-0 bottom-0 w-64 h-screen border-r border-slate-200 md:left-auto md:shrink-0 z-10 md:!opacity-100 md:!block dark:border-slate-200 dark:bg-slate-900"
                    enter="transition ease-out duration-200 transform"
                    enterFrom="opacity-0 -translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="transition ease-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {/* Gradient bg displaying on light layout only */}
                    <div
                        className="absolute inset-0 -left-[9999px] bg-gradient-to-b from-slate-50 to-white pointer-events-none -z-10 "
                        aria-hidden="true"
                    ></div>

                    <div className="fixed top-36 bottom-0 w-64 px-4 sm:px-6 md:pl-0 md:pr-8 overflow-y-auto no-scrollbar">
                        <div className="pt-24 md:pt-28 pb-8">
                            {/* Docs nav */}
                            <nav className="md:block">
                                {/* 1st level */}
                                <SidebarLinkGroup
                                    open={segments.includes("help-center")}
                                >
                                    {(handleClick, open) => {
                                        return (
                                            <>
                                                <a
                                                    href="#0"
                                                    className={`relative flex items-center text-lg font-[650] text-slate-800 p-1 before:absolute before:inset-0 before:rounded before:bg-gradient-to-tr before:from-blue-400 before:to-purple-500 before:opacity-20 before:-z-10 before:pointer-events-none dark:text-black ${
                                                        !segments.includes(
                                                            "help-center",
                                                        ) && "before:hidden"
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(
                                                            "/help-center/intro",
                                                        );
                                                    }}
                                                >
                                                    <BooksFaqSidebarrSvg />
                                                    <span>
                                                        {translate(
                                                            "HELPCENTER",
                                                            "All_Category",
                                                        )}
                                                    </span>
                                                </a>
                                            </>
                                        );
                                    }}
                                </SidebarLinkGroup>
                                {/* Démarrer avec Infinexia */}
                                <ul className="text-sm text-black dark:text-black">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Start_Infinexia",
                                        )}
                                        open={segments.includes(
                                            "getting-start",
                                        )}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/getting-start/open-individual-account`
                                                    : "/help-center/getting-start/open-individual-account"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Individual_Account",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/getting-start/open-business-account`
                                                    : "/help-center/getting-start/open-business-account"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Business_Account",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/getting-start/subscription`
                                                    : "/help-center/getting-start/subscription"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Select_Offer",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Comptes */}
                                <ul className="text-sm">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Accounts_Gestion",
                                        )}
                                        open={segments.includes(
                                            "accounts-management",
                                        )}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/accounts-management/currencies-accounts`
                                                    : "/help-center/accounts-management/currencies-accounts"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Currencies_Accounts",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/accounts-management/safes-accounts`
                                                    : "/help-center/accounts-management/safes-accounts"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Safes_Accounts",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/accounts-management/jackpots-accounts`
                                                    : "/help-center/accounts-management/jackpots-accounts"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Jackpots_Accounts",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/accounts-management/subaccounts`
                                                    : "/help-center/accounts-management/subaccounts"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "SubAccounts",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Transactions */}
                                <ul className="text-sm">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Transactions",
                                        )}
                                        open={segments.includes("transactions")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/transactions/receive-money`
                                                    : "/help-center/transactions/receive-money"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Receive_Money",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/transactions/send-money`
                                                    : "/help-center/transactions/send-money"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Send_Money",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/cards/physical-cards`
                                                    : "/help-center/cards/physical-cards"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Bank_Transfers",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Cartes bancaires */}
                                <ul className="text-sm">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Bank_Cards",
                                        )}
                                        open={segments.includes("cards")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/cards/physical-cards`
                                                    : "/help-center/cards/physical-cards"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Physical_Cards",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/cards/virtual-cards`
                                                    : "/help-center/cards/virtual-cards"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Virtual_Cards",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Paramètres & sécurité */}
                                <ul className="text-sm">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Settings_Security",
                                        )}
                                        open={segments.includes(
                                            "settings-security",
                                        )}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/settings-security/edit-profile`
                                                    : "/help-center/settings-security/edit-profile"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Modify_Profile",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/settings-security/login-password`
                                                    : "/help-center/settings-security/login-password"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Login_PassWord",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/settings-security/transaction-password`
                                                    : "/help-center/settings-security/transaction-password"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Transac_PassWord",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/settings-security/2fa-authentication`
                                                    : "/help-center/settings-security/2fa-authentication"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Authentication_2FA",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Gagner des récompenses */}
                                <ul className="text-sm">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Earning_Rewards",
                                        )}
                                        open={segments.includes("rewards")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/rewards/infinity-points`
                                                    : "/help-center/rewards/infinity-points"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER_REWARDS",
                                                "Infinity_Title",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/rewards/invite-a-friends`
                                                    : "/help-center/rewards/invite-a-friends"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Sponsor_Friends",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/rewards/ambassador`
                                                    : "/help-center/rewards/ambassador"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Become_Ambassador",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* Divers */}
                                <ul className="text-sm">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Various",
                                        )}
                                        open={segments.includes("various")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/various/contact-customer-service`
                                                    : "/help-center/various/contact-customer-service"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "How_Customer_Service",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/various/working-with-infinexia`
                                                    : "/help-center/various/working-with-infinexia"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Working_With_Infinexia",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/various/press-service`
                                                    : "/help-center/various/press-service"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Press service",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                <>
                                    <div
                                        className={`mt-10 relative flex items-center text-lg font-[650] text-slate-800 p-1 before:absolute before:inset-0 before:rounded before:bg-gradient-to-tr before:from-blue-400 before:to-purple-500 before:opacity-20 before:-z-10 before:pointer-events-none dark:text-black ${
                                            !segments.includes("help-center") &&
                                            "before:hidden"
                                        }`}
                                    >
                                        <BooksFaqSidebarrSvg />
                                        <span>
                                            {translate(
                                                "HELPCENTER",
                                                "Business_Functionality",
                                            )}
                                        </span>
                                    </div>
                                </>
                                {/* Devis / Factures */}
                                <ul className="text-sm">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Estimates_Invoices",
                                        )}
                                        open={segments.includes(
                                            "estimates-invoices",
                                        )}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/estimates-invoices/configure-estimates-invoices`
                                                    : "/help-center/estimates-invoices/configure-estimates-invoices"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Config_Estimates_Invoices",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/estimates-invoices/create-send-estimates`
                                                    : "/help-center/estimates-invoices/create-send-estimates"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Create_Estimates",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/estimates-invoices/create-send-invoices`
                                                    : "/help-center/estimates-invoices/create-send-invoices"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Create_Invoices",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                                {/* API de paiement */}
                                <ul className="text-sm">
                                    <SidebarLinkSubgroup
                                        title={translate(
                                            "HELPCENTER",
                                            "Payment_Api",
                                        )}
                                        open={segments.includes("payment-api")}
                                    >
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/payment-api/request-api-access`
                                                    : "/help-center/payment-api/request-api-access"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Api_Access",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/payment-api/api-integration`
                                                    : "/help-center/payment-api/api-integration"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "API_Intégration",
                                            )}
                                        </SidebarLink>
                                        <SidebarLink
                                            href={
                                                lang
                                                    ? `/${lang}/help-center/payment-api/plugin-integration`
                                                    : "/help-center/payment-api/plugin-integration"
                                            }
                                        >
                                            {translate(
                                                "HELPCENTER",
                                                "Woo_Plugin",
                                            )}
                                        </SidebarLink>
                                    </SidebarLinkSubgroup>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </Transition>
            </div>
        </>
    );
}
