import { CroppedArea } from "../Components/Wallets/UserAssetDetail/detail";
import { BaseCurrency, EurUsdGbp } from "../Models/BaseCurrency";
import { countryList, EuropeanCountries } from "../Models/CountryList";
import User, { PublicUserDta } from "../Models/User";
import { UserCryptoWallet } from "../Models/UserWallet";
/**
 * get the url of  the photo stored in server
 *
 * @param url
 * @returns
 */
const getPhotoUrl = (url: string): string => {
    if (!url) return "";
    if (url.includes("http://") || url.includes("https://")) return url;
    return process.env.REACT_APP_API_URL + "/" + url;
};

const creatUrl = (url: string) => {
    return process.env.REACT_APP_PUBLIC_URL + "/" + url;
};

const getFrontLink = (url: string) => {
    return process.env.REACT_APP_BASE_NAME == "/" ||
        !process.env.REACT_APP_BASE_NAME
        ? process.env.REACT_APP_PUBLIC_URL + url
        : process.env.REACT_APP_PUBLIC_URL +
              process.env.REACT_APP_BASE_NAME +
              url;
};

const getAxiosErrorMessage = (err: any, code = false): string => {
    const error =
        err.response && err.response.data && err.response.data.message
            ? code
                ? err.response.data.code
                : err.response.data.message
            : err.message;

    return error;
};

const getProfilPicsUrl = (user: User): string => {
    if (user.photo) {
        return getPhotoUrl(user.photo);
    } else {
        return process.env.PUBLIC_URL + "/dist/image/avatar.webp";
    }
};

const getProfilPicsUrlV2 = (user?: PublicUserDta): string => {
    if (user && user.photo) {
        return getPhotoUrl(user.photo);
    } else {
        return process.env.PUBLIC_URL + "/dist/image/avatar.webp";
    }
};

const getPulicImage = (path: string): string => {
    if (path.includes("/uploads/backround/INF"))
        return getPhotoUrl(path.replace("/dist/image/Background/", ""));

    return path;
    // return process.env.PUBLIC_URL + path;
};

const getPulicImageV2 = (path: string): string => {
    return (
        process.env.PUBLIC_URL +
        (process.env.REACT_APP_BASE_NAME !== "/"
            ? process.env.REACT_APP_BASE_NAME
            : "") +
        path
    );
};

export const getBeautifulFormatedDateWithTime = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    const hour = new Intl.DateTimeFormat("en", {
        hour: "numeric",
        hourCycle: "h23",
    }).format(d);
    let minute = new Intl.DateTimeFormat("en", { minute: "numeric" }).format(d);
    minute = parseInt(minute) < 10 ? "0" + minute : minute;

    return `${da} ${mo} ${ye} ${hour}:${minute}`;
};
export const getBeautifulFormatedDateWithTimeSeparate = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    const hour = new Intl.DateTimeFormat("en", {
        hour: "numeric",
        hourCycle: "h23",
    }).format(d);
    let minute = new Intl.DateTimeFormat("en", { minute: "numeric" }).format(d);
    minute = parseInt(minute) < 10 ? "0" + minute : minute;

    return {
        hour: `${hour}:${minute}`,
        date: `${da} ${mo} ${ye}`,
    };
};

export const getBeautifulFormatedDate = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${da} ${mo} ${ye}`;
};

export const getBeautifulFormatedDateFr = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "2-digit" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${da}/${mo}/${ye}`;
};

export const getBeautifulFormatedDateFrV2 = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${da}/${mo}/${ye}`;
};

export const getBeautifulDateChart = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${mo}-${da}-${ye}`;
};

export const getDateInputValue = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${ye}-${mo}-${da}`;
};

export const getDateFormat_D_M_YY = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${da}/${mo}/${ye}`;
};

export const isFunction = (name: string) => {
    return name && {}.toString.call(name) === "[object Function]";
};

export const getPercentValue = (value: number, total: number): number => {
    if (isNaN(value) || isNaN(total)) return 0;
    const res = value > 0 ? (value * 100) / total : 0;
    return formatDecimal(res, 2);
};

export const formatDecimal = (value: number, decimal: number): number => {
    if (isNaN(value)) return 0;
    // Utilisation de Math.floor pour tronquer
    const factor = Math.pow(10, decimal);
    return Math.floor(value * factor) / factor;
};
export const formatAmount = (value: number | string, decimal = 8): string => {
    const numValue = Number(value);
    if (isNaN(numValue)) return "0,00";

    // Utilisation de la troncature avec formatDecimal
    const fixedValue = formatDecimal(numValue, decimal).toFixed(decimal);

    // Séparation des parties entière et décimale
    const [integerPart, decimalPart] = fixedValue.split(".");

    // Ajout d'espaces comme séparateurs de milliers
    const integerWithSeparators = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "\u00A0",
    );

    // Traitement spécifique pour les montants en crypto
    if (decimal > 2) {
        // On garde les 2 premières décimales
        const trimmedDecimalPart = decimalPart.slice(0, 2);

        // Supprimer les zéros inutiles après les 2 décimales
        const nonZeroDecimalPart = decimalPart.replace(/0+$/, "");

        // Reconstitution du nombre formaté
        return `${integerWithSeparators},${trimmedDecimalPart}${nonZeroDecimalPart.length > 2 ? nonZeroDecimalPart.slice(2) : ""}`;
    }

    // Reconstitution du nombre formaté pour fiat ou montant crypto avec 2 décimales
    return `${integerWithSeparators}${decimal > 0 ? "," : ""}${(decimalPart ?? "").padEnd(decimal, "0")}`;
};

export const formatAmountV2 = (value: number, decimal = 8): string => {
    if (isNaN(value)) return "0";

    // Utilisation de la troncature
    const truncatedValue = formatDecimal(value, decimal);

    return truncatedValue.toLocaleString("fr", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
    });
};

export const revertFormatAmount = (value: string): number => {
    return Number(value.replace(/\s/g, "").replace(",", "."));
};

export const getExtension = (filename: string) => {
    const parts = filename.split(".");

    return parts[parts.length - 1];
};
const capitalizeChar = (text: string, separator: string): string => {
    const arr = text.split(separator);

    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2;
};

const getAbsoluteValue = (num: number): number => {
    return Math.abs(num);
};

const capitalizeFirstLetter = (str: string): string => {
    if (str.length === 0) {
        return str;
    }

    const firstChar = str.charAt(0).toUpperCase();
    const restOfString = str.slice(1);

    return firstChar + restOfString;
};

const getFee = (amount: number, percent_fee: number): number => {
    if (percent_fee <= 0) return 0;
    return (amount * percent_fee) / 100;
};

const convertAmount = (amount: number, price: number): number => {
    if (price <= 0) return 0;
    return amount / price;
};

const convertAmountSwap = (
    amount: number,
    debited: UserCryptoWallet,
    credited: UserCryptoWallet,
): number => {
    if (amount <= 0) return 0;

    const usd_amount = amount * debited.current_price;

    const test = usd_amount / credited.current_price;

    return test;
};

export function getCurrencySymbol(currency: string) {
    const currencySymbols: { [key: string]: string } = {
        USD: "$",
        EUR: "€",
        GBP: "£",
        // Ajoutez d'autres devises ici
    };

    // Retourner le symbole uniquement, sinon retourner une chaîne vide
    return currencySymbols[currency] || "";
}

const roundValue = (value: number | undefined, decimal = 2): number => {
    if (Number(value) == 0 || value === undefined) return 0;
    return parseFloat(value.toFixed(decimal));
};

const convertDevise = (
    fromUsdPrice: number,
    toUsdPrice: number,
    amount: number,
    decimal = 8,
): number => {
    return roundValue((fromUsdPrice * amount) / toUsdPrice, decimal);
};

const getFormatedAmount = (
    amount: number | string,
    currency: string,
    decimal: number,
): string => {
    return `${formatAmount(amount, decimal)} ${currency}`;
};
const getConvertedFormatedAmount = (
    amount: number,
    base_currency: BaseCurrency,
    devise = 0, // devise rate in dollars
): string => {
    if (!base_currency) {
        base_currency = { currency: "USD", symbol: "$", unit: 1 };
    }
    const convertAmount = convertDevise(amount, base_currency.unit, 1);

    let comma = 8;
    if (devise > 0) {
        if (devise < 0.5) comma = 8;
        else if (devise < 1) comma = 4;
        else comma = 2;
    } else {
        if (convertAmount < 0.5) comma = 8;
        else if (convertAmount < 1) comma = 4;
        else comma = 2;
    }

    let total_amount = formatDecimal(convertAmount, comma); // Utilisation de formatDecimal
    if (devise === 0 && total_amount === 0) {
        total_amount = convertAmount;
    }

    return getFormatedAmount(total_amount, base_currency.symbol, comma);
};

const getConvertedFormatedAmount2 = (
    amount: number,
    base_system_currency: BaseCurrency,
    base_currency_user: BaseCurrency,
    comma = 8,
): string => {
    const convertAmount = convertDevise(
        base_system_currency.unit,
        base_currency_user.unit,
        amount,
    );

    // if (convertAmount < 0.5) comma = 8;
    // else if (convertAmount < 1) comma = 4;
    // else comma = 2;

    const formattedAmount = convertAmount.toFixed(comma);

    return getFormatedAmount(formattedAmount, base_currency_user.symbol, comma);
};

export const formatValue = (value: number, type?: string): string => {
    const decimal = type === "fiat" ? 2 : 6;
    return formatAmountV2(value, decimal);
};

const getCountryName = (countryCode: string): string => {
    if (!countryCode) return "";
    const findCountry = countryList.find(
        (cnt) => cnt.value.toUpperCase() === countryCode.toUpperCase(),
    );

    return findCountry?.label || countryCode;
};

const isMinor = (dob: Date) => {
    dob.setHours(0, 0);
    const date = new Date();
    date.setHours(0, 0);
    date.setFullYear(date.getFullYear() - 18);

    return dob > date;
};

const getAbbreviation = (user_crypto: UserCryptoWallet | undefined): string => {
    if (!user_crypto) return "";
    return user_crypto.personal_asset_detail
        ? `${user_crypto.currency}`
        : user_crypto.abbreviation;
};

export const getDateFormat_M_D_YY = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${mo}-${da}-${ye}`;
};

const formatNumberWithTwoDecimals = (num: number): string => {
    // Utilisation de formatDecimal pour tronquer à deux décimales
    const truncatedNum = formatDecimal(num, 2);

    // Retourne le nombre avec exactement deux décimales
    return truncatedNum.toFixed(2).replace(".", ",");
};

const convertDateStringToDate = (dateString: string): Date | null => {
    const months: { [key: string]: string } = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
    };

    // Split the date string and remove the comma if present
    const dateParts = dateString.replace(",", "").split(" ");

    if (dateParts.length !== 3) {
        console.error("Invalid date format:", dateString);
        return null;
    }

    const [month, day, year] = dateParts;

    if (!months[month]) {
        console.error("Invalid month:", month);
        return null;
    }

    if (isNaN(parseInt(day)) || isNaN(parseInt(year))) {
        console.error("Invalid day or year:", day, year);
        return null;
    }

    // Pad the day with leading zero if necessary
    const paddedDay = day.padStart(2, "0");

    const formattedDate = `${year}-${months[month]}-${paddedDay}T00:00:00.000Z`;

    const dateObject = new Date(formattedDate);

    // Check if the date object is valid
    if (isNaN(dateObject.getTime())) {
        console.error("Resulting date is invalid:", formattedDate);
        return null;
    }

    return dateObject;
};

const convertImageToBase64 = async (url: string) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
            if (typeof reader.result === "string") {
                return reader.result;
            }
        };
        reader.readAsDataURL(blob);
    } catch (error) {
        console.error("Error fetching or converting image:", error);
    }
};

const checkUserCurrency = (
    amount: number,
    base_system_currency: BaseCurrency,
    base_currency_user: BaseCurrency,
    comma = 8,
): string => {
    const res = Object.values(EurUsdGbp).includes(
        base_currency_user.currency as EurUsdGbp,
    );
    if (res) {
        // Utilisation de formatDecimal pour tronquer
        return `${formatDecimal(amount, 2)} ${base_currency_user.symbol}`;
    }

    return getConvertedFormatedAmount2(
        amount,
        base_system_currency,
        base_currency_user,
        comma,
    ).replace(".", ",");
};
const removeCurrencySymbol = (currencyString: string) => {
    // const currencyString = "52 910,91 £";

    // Step 1: Remove the currency symbol and spaces
    const cleanedString = currencyString.replace(/[^\d,.-]/g, "");

    // Step 2: Replace the comma with a dot if it's used as the decimal separator
    const normalizedString = cleanedString.replace(",", ".");

    // Step 3: Convert the string to a number
    const numberValue = parseFloat(normalizedString);
    return numberValue;
};

const formatMarketCap = (
    value: any,
    currency: BaseCurrency,
    showSymbol = true,
) => {
    const numericValue =
        typeof value === "string"
            ? parseFloat(value.replace(/\s/g, "").replace("$", ""))
            : value;

    if (isNaN(numericValue) || numericValue === 0) {
        return "N/A";
    }

    let formattedValue: string;
    if (numericValue >= 1e12) {
        formattedValue =
            formatDecimal(numericValue / 1e12, 2)
                .toString()
                .replace(".", ",") + "T";
    } else if (numericValue >= 1e9) {
        formattedValue =
            formatDecimal(numericValue / 1e9, 2)
                .toString()
                .replace(".", ",") + "B";
    } else if (numericValue >= 1e6) {
        formattedValue =
            formatDecimal(numericValue / 1e6, 2)
                .toString()
                .replace(".", ",") + "M";
    } else {
        formattedValue = numericValue.toLocaleString("fr-FR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    if (formattedValue.endsWith(",00") || formattedValue.endsWith(",0")) {
        formattedValue = formattedValue.slice(0, formattedValue.indexOf(","));
    }

    return showSymbol ? formattedValue + " " + currency.symbol : formattedValue;
};

const getBase64Image = (url: string): any => {
    return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result); // Base64 data URL
                };
                reader.readAsDataURL(blob);
            });
        });
};

const getCroppedImg = (
    imageSrc: string,
    pixelCrop: CroppedArea,
): Promise<File> => {
    return new Promise(async (resolve, reject) => {
        const canvas = document.createElement("canvas");
        const base64Image = imageSrc; // Remplacez par votre code Base64
        const imageUrl = `data:image/jpeg;base64,${base64Image}`;
        const img = new Image();

        // if (!imageSrc.startsWith("data:image")) {
        img.crossOrigin = "anonymous";
        // }

        img.src = imageUrl;

        img.onload = () => {
            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;
            const ctx = canvas.getContext("2d");

            if (ctx) {
                // Assurez-vous d'effacer le fond et d'appliquer la transparence
                ctx.globalAlpha = 0; // Réglez l'alpha pour forcer la transparence
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // Dessinez un rectangle transparent pour être sûr
                ctx.fillStyle = "rgba(0,0,0,0)";
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // Rétablir l'alpha à 1 avant de dessiner l'image
                ctx.globalAlpha = 1;

                // Dessiner l'image recadrée
                ctx.drawImage(
                    img,
                    pixelCrop.x,
                    pixelCrop.y,
                    pixelCrop.width,
                    pixelCrop.height,
                    0,
                    0,
                    pixelCrop.width,
                    pixelCrop.height,
                );

                // Exporter en PNG pour conserver la transparence
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(
                            new File([blob], "croppedImage.png", {
                                type: "image/png",
                            }),
                        );
                    } else {
                        reject(
                            new Error(
                                "Erreur lors de la création du blob de l'image recadrée",
                            ),
                        );
                    }
                }, "image/png");
            }
        };

        img.onerror = (error) => reject(error);
    });
};

const IsUserEuropean = (user?: User): boolean => {
    const isEuropean = user?.country
        ? EuropeanCountries.includes(user.country)
        : false;

    return isEuropean;
};

const getPointsForOneEuro = (pointValue: number): number => {
    if (pointValue <= 0) return 0;
    return 1 / pointValue;
};

const UtilsService = {
    getAxiosErrorMessage,
    getPhotoUrl,
    getProfilPicsUrl,
    getBeautifulFormatedDateWithTime,
    getPulicImage,
    getPulicImageV2,
    isFunction,
    getBeautifulFormatedDate,
    getPercentValue,
    formatDecimal,
    getProfilPicsUrlV2,
    formatAmount,
    creatUrl,
    getFrontLink,
    getExtension,
    capitalizeChar,
    getAbsoluteValue,
    capitalizeFirstLetter,
    getFee,
    convertAmount,
    formatAmountV2,
    convertAmountSwap,
    getBeautifulFormatedDateFr,
    getDateFormat_D_M_YY,
    getFormatedAmount,
    getConvertedFormatedAmount,
    formatValue,
    getCountryName,
    getConvertedFormatedAmount2,
    getBeautifulFormatedDateFrV2,
    getDateInputValue,
    isMinor,
    getBeautifulDateChart,
    getAbbreviation,
    formatNumberWithTwoDecimals,
    convertDateStringToDate,
    convertImageToBase64,
    checkUserCurrency,
    formatMarketCap,
    removeCurrencySymbol,
    getCurrencySymbol,
    getCroppedImg,
    IsUserEuropean,
    getPointsForOneEuro,
};
export default UtilsService;
