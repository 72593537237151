import React, { useEffect, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import translator from "../../../Components/Extra/Translation/Translate";
import { barOptions } from "./ChartData";
import "react-datepicker/dist/react-datepicker.css";
import UtilsService from "../../../Services/UtilsService";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import useLanguage from "../../../Services/Hooks/useLanguage";
import { DataSales } from "../../../Models/Transaction";

interface DateValue {
    startDate: Date;
    endDate: Date;
}

interface GlobalBarChartProps {
    dataSales?: DataSales;
    width?: number;
    height?: number;
    isdefault?: boolean;
}

export default function GlobalBarChart({
    dataSales,
    width,
    height,
    isdefault,
}: GlobalBarChartProps) {
    const { base: baseCurrency } = useBaseCurrency();
    const { translate } = translator();
    const { language } = useLanguage();
    const monthLabels = [
        translate("Admin_Dashboard", "Last_Month"),
        translate("Admin_Dashboard", "This_Month"),
    ];

    const data = useMemo(() => {
        return {
            lastMonth: dataSales?.lastMonth ?? [],
            thisMonth: dataSales?.thisMonth ?? [],
        };
    }, [dataSales, language]);

    const chartData = useMemo(() => {
        const createDataMap = (dataset: any[]) => {
            const map = new Map(
                dataset.map((dt) => [dt.date_group, dt.sum_usd_price]),
            );
            return monthLabels.map((label) => {
                const dataForPeriod =
                    label === translate("Admin_Dashboard", "This_Month")
                        ? data.thisMonth
                        : data.lastMonth;
                return dataForPeriod.reduce((acc: any, curr: any) => {
                    return (
                        acc + (curr.date_group ? map.get(curr.date_group) : 0)
                    );
                }, 0);
            });
        };

        const totalData = createDataMap([...data.lastMonth, ...data.thisMonth]);

        return {
            labels: monthLabels,
            datasets: [
                {
                    label: translate("Admin_Dashboard", "Total"),
                    data: totalData,
                    backgroundColor: "#3695D2", // Couleur de la barre
                },
            ],
        };
    }, [data, language]);

    const totals = useMemo(() => {
        const thisMonth = data.thisMonth.reduce(
            (sum: number, dt: any) => sum + (dt.sum_usd_price || 0),
            0,
        );
        const lastMonth = data.lastMonth.reduce(
            (sum: number, dt: any) => sum + (dt.sum_usd_price || 0),
            0,
        );

        return {
            thisMonth: UtilsService.getConvertedFormatedAmount(
                thisMonth,
                baseCurrency,
                2,
            ),
            lastMonth: UtilsService.getConvertedFormatedAmount(
                lastMonth,
                baseCurrency,
                2,
            ),
        };
    }, [data, language]);
    return (
        <div className="col-span-12 lg:col-span-6">
            <div className="flex w-full flex-col col-span-full h-full box sm:col-span-12 bg-white dark:bg-slate-800 shadow-xl rounded-sm border dark:!border-2 dark:!border-slate-700">
                <header className="sm:!px-2 md:!px-5 py-1 border-b border-[#d0d0d0] h-[55px] flex justify-between items-center dark:!border-b dark:!border-slate-700">
                    <h2 className="font-semibold text-left">
                        {translate("Admin_Dashboard", "Sales_Report")}
                    </h2>
                </header>
                <div className="flex flex-col md:flex-row md:items-center py-3 px-5">
                    <div className="flex">
                        <div>
                            <div className="text-primary text-2xl font-bold dark:text-slate-300">
                                {totals.thisMonth}
                            </div>
                            <div className="mt-0.5 text-slate-500">
                                {translate("Admin_Dashboard", "This_Month")}
                            </div>
                        </div>
                        <div className="dark:border-darkmode-300 mx-4 h-12 w-px border border-dashed border-slate-200 xl:mx-5"></div>
                        <div>
                            <div className="text-2xl font-bold text-slate-500 ">
                                {totals.lastMonth}
                            </div>
                            <div className="mt-0.5 text-slate-500">
                                {translate("Admin_Dashboard", "Last_Month")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-chart">
                    {chartData && (
                        <div className="w-full sm:!px-3 md:!px-5 py-1 h-[300px]">
                            <Bar options={barOptions} data={chartData} />
                        </div>
                    )}
                </div>
                {/* Chart built with Chart.js 3 */}
                <div
                    className={isdefault == true ? "blur-sm grow" : "grow"}
                ></div>
                {isdefault && (
                    <span className="absolute z-1 top-[50%] w-full flex justify-center text-center pr-[25px] pl-[25px]">
                        {translate(
                            "Dashboard",
                            "Insufficient_data_display_graph",
                        )}
                    </span>
                )}
            </div>
        </div>
    );
}
