import React, { useMemo } from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import useLanguage from "../../../Services/Hooks/useLanguage";
import { hexToRGB, tailwindConfig } from "../../../Components/utils/utils";
import LineChartAdmin from "./LineChartAdmin";
import { DataSales } from "../../../Models/Transaction";

interface AdminLineChartProps {
    dataSales?: DataSales;
}
export default function AdminLineChart({ dataSales }: AdminLineChartProps) {
    const { translate } = translator();
    const { language } = useLanguage();
    const chartData2 = {
        labels: [
            "12-01-2020",
            "01-01-2021",
            "02-01-2021",
            "03-01-2021",
            "04-01-2021",
            "05-01-2021",
            "06-01-2021",
            "07-01-2021",
            "08-01-2021",
            "09-01-2021",
            "10-01-2021",
            "11-01-2021",
            "12-01-2021",
            "01-01-2022",
            "02-01-2022",
            "03-01-2022",
            "04-01-2022",
            "05-01-2022",
            "06-01-2022",
            "07-01-2022",
            "08-01-2022",
            "09-01-2022",
            "10-01-2022",
            "11-01-2022",
            "12-01-2022",
            "01-01-2023",
            "02-01-2023",
            "03-01-2023",
            "04-01-2023",
            "05-01-2023",
            "06-01-2023",
            "07-01-2023",
            "08-01-2023",
            "09-01-2023",
            "10-01-2023",
            "11-01-2023",
            "12-01-2023",
            "01-01-2024",
            "02-01-2024",
            "03-01-2024",
            "04-01-2024",
        ],
        datasets: [
            // Indigo line
            {
                label: translate("Admin_Dashboard", "This_Month"),
                data: [
                    0, 2.5, 2.5, 4, 2.5, 3.8, 5, 9, 7.5, 11, 14, 15, 17, 15, 14,
                    9, 15, 26, 16, 18, 15, 20, 18, 19, 19, 24, 29, 26, 39, 27,
                    35, 32, 29, 35, 36, 34, 39, 36, 41, 41, 48,
                ],
                borderColor: "#3b82f6",
                fill: true,
                backgroundColor: `rgba(${hexToRGB(
                    tailwindConfig.theme.colors.blue[500],
                )}, 0.08)`,
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: "#3b82f6",
                pointHoverBackgroundColor: "#3b82f6",
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                clip: 20,
            },
            // gray line
            {
                label: translate("Admin_Dashboard", "Last_Month"),
                data: [
                    0.7, 3.5, 4.5, 3.5, 4.2, 4.6, 6, 7, 6, 6, 11, 13, 14, 18,
                    17, 15, 13, 16, 20, 21, 24, 22, 20, 22, 25, 18, 21, 23, 24,
                    32, 28, 29, 35, 37, 42, 32, 32, 33, 33, 37, 32,
                ],
                borderColor: `rgba(${hexToRGB(
                    tailwindConfig.theme.colors.slate[500],
                )}, 0.75)`,
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: `rgba(${hexToRGB(
                    tailwindConfig.theme.colors.slate[500],
                )},  0.75)`,
                backgroundColor: `rgba(${hexToRGB(
                    tailwindConfig.theme.colors.slate[500],
                )},  0.75)`,
                pointHoverBackgroundColor: `rgba(${hexToRGB(
                    tailwindConfig.theme.colors.slate[500],
                )},  0.75)`,
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                clip: 20,
            },
        ],
    };

    const data = useMemo(() => {
        return {
            lastMonth: dataSales?.lastMonth ?? [],
            thisMonth: dataSales?.thisMonth ?? [],
        };
    }, [dataSales, language]);
    const chartData = useMemo(() => {
        const allDates = [
            ...new Set(
                [...data.lastMonth, ...data.thisMonth].map(
                    (dt: any) => dt.date_group,
                ),
            ),
        ].sort();

        const createDataMap = (dataset: any[]) => {
            const map = new Map(
                dataset.map((dt) => [dt.date_group, dt.sum_usd_price]),
            );
            return allDates.map((date) => map.get(date) ?? 0);
        };

        return {
            labels: allDates,
            datasets: [
                // This Month - Blue Line
                {
                    label: translate("Admin_Dashboard", "This_Month"),
                    data: createDataMap(data.thisMonth),
                    borderColor: "#3b82f6",
                    fill: true,
                    backgroundColor: `rgba(${hexToRGB(tailwindConfig.theme.colors.blue[500])}, 0.08)`,
                    borderWidth: 2,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: "#3b82f6",
                    pointHoverBackgroundColor: "#3b82f6",
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    clip: 20,
                },
                // Last Month - Gray Line
                {
                    label: translate("Admin_Dashboard", "Last_Month"),
                    data: createDataMap(data.lastMonth),
                    borderColor: `rgba(${hexToRGB(tailwindConfig.theme.colors.slate[500])}, 0.75)`,
                    borderWidth: 2,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig.theme.colors.slate[500])}, 0.75)`,
                    pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig.theme.colors.slate[500])}, 0.75)`,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    clip: 20,
                },
            ],
        };
    }, [data, language]);

    const total_data = [...data.thisMonth, ...data.lastMonth].reduce(
        (sum: number, dt: any) => sum + (dt.sum_usd_price || 0),
        0,
    );

    return (
        <div className="flex w-full flex-col box col-span-12 lg:col-span-6 h-full bg-white dark:bg-slate-800 shadow-xl rounded-sm border dark:!border-2 dark:!border-slate-700">
            <header className="sm:!px-2 md:!px-5 py-1 border-b border-[#d0d0d0] h-[55px] flex justify-between items-center dark:!border-b dark:!border-slate-700">
                <div className="font-semibold text-left">
                    {translate("ACCOUNTING", "Sales_Evo")}
                </div>
            </header>

            <LineChartAdmin
                data={total_data > 0 && dataSales ? chartData : chartData2}
                width={800}
                height={300}
                isdefault={!(total_data > 0 && dataSales !== undefined)}
                total={dataSales ? total_data : 0}
            />
        </div>
    );
}
