import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import HelmetComponent from "../../Admin/Layout/HelmetComponent/HelmetComponent";
import CubeSvg from "../../Components/Extra/Svg/CubeSvg";
import BannerIntro from "../Layout/BannerIntro";
import BookSidebarFaqSvg from "../../Components/Extra/Svg/BookSidebarFaqSvg";
import BookIntroFaqSvg from "../../Components/Extra/Svg/BookIntroFaqSvg";

const HelpCenterIntro = () => {
    const { translate } = translator();
    const { lang } = useParams();
    const [isMobile, setIsMobile] = useState(false);

    // Détection de la version mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Appeler lors du montage
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Liste des données des liens
    const links = [
        {
            desktop: "/help-center/getting-start/open-individual-account",
            mobile: "/help-center/getting-start/",
            title: translate("HELPCENTER", "Start_Infinexia"),
            description: translate("HELPCENTER", "Start_Infinexia_Desc"),
        },
        {
            desktop: "/help-center/accounts-management/currencies-accounts",
            mobile: "/help-center/accounts-management",
            title: translate("HELPCENTER", "Accounts_Gestion"),
            description: translate("HELPCENTER", "Accounts_Gestion_Desc"),
        },
        {
            desktop: "/help-center/transactions/receive-money",
            mobile: "/help-center/transactions",
            title: translate("HELPCENTER", "Transactions"),
            description: translate("HELPCENTER", "Transactions_Desc"),
        },
        {
            desktop: "/help-center/cards/physical-cards",
            mobile: "/help-center/cards",
            title: translate("HELPCENTER", "Bank_Cards"),
            description: translate("HELPCENTER", "Bank_Cards_Desc"),
        },
        {
            desktop: "/help-center/settings-security/login-password",
            mobile: "/help-center/settings-security",
            title: translate("HELPCENTER", "Settings_Security"),
            description: translate("HELPCENTER", "Settings_Security_Desc"),
        },
        {
            desktop: "/help-center/rewards/infinity-points",
            mobile: "/help-center/rewards",
            title: translate("HELPCENTER", "Earning_Rewards"),
            description: translate("HELPCENTER", "Earning_Rewards_Desc"),
        },
        {
            desktop: "/help-center/various/contact-customer-service",
            mobile: "/help-center/various",
            title: translate("HELPCENTER", "Various"),
            description: translate("HELPCENTER", "Various_Desc"),
        },
        {
            desktop:
                "/help-center/estimates-invoices/configure-estimates-invoices",
            mobile: "/help-center/estimates-invoices",
            title: translate("HELPCENTER", "Estimates_Invoices"),
            description: translate("HELPCENTER", "Estimates_Invoices_Desc"),
        },
        {
            desktop: "/help-center/payment-api/request-api-access",
            mobile: "/help-center/payment-api",
            title: translate("HELPCENTER", "Payment_Api"),
            description: translate("HELPCENTER", "Payment_Api_Desc"),
        },
    ];

    return (
        <>
            <HelmetComponent
                title={translate("HELPCENTER", "Help_Center")}
                description={translate("HELPCENTER", "Start_Infinexia_Desc")}
                link="/help-center/intro"
                keywords={translate("SEO", "Keys_Home")}
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            />
            <div className="">
                <BannerIntro
                    title={translate("HELPCENTER", "All_Category")}
                ></BannerIntro>
                <section className="flex xl:space-x-12">
                    <div className="mb-[50px]">
                        <div className="w-full rounded-lg mx-auto grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                            {/* First Line Box */}
                            {links.map((link, index) => (
                                <NavLink
                                    to={isMobile ? link.mobile : link.desktop}
                                    className="box !bg-white text-left p-4 px-4 rounded-lg shadow-lg !border !border-gray-300 zoom-in"
                                >
                                    <div className="sm:!min-h-[70px] md:!min-h-[80px]">
                                        <h2 className="text-base md:!text-lg font-bold flex items-center">
                                            <BookIntroFaqSvg />
                                            {link.title}
                                        </h2>
                                        <p className="text-sm md:!text-base text-black font-normal">
                                            {link.description}
                                        </p>
                                    </div>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default HelpCenterIntro;
