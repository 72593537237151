import {
    ITransaction,
    ITransactionParentChild,
    TransactionType,
} from "../../Admin/Components/User/List/Utils";
import { DatatableFilter } from "../../Models/DatatableFilter";
import Transaction, {
    DataSales,
    OTransactionCount,
    TypeDateAmount,
} from "../../Models/Transaction";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): Transaction => {
    const transaction = new Transaction(
        item.description,
        item.amount,
        item.usd_price,
        item.status,
        item.created_at,
        item.user,
    );

    transaction.description = item.description;
    transaction.amount = item.amount;
    transaction.status = item.status;
    transaction.type = item.type;
    transaction.user_wallet = item.user_wallet;
    transaction.date = UtilsService.getBeautifulFormatedDateWithTime(
        item.created_at,
    );
    transaction.date_fr = UtilsService.getDateFormat_D_M_YY(item.created_at);
    transaction.user = item.user;
    transaction.fee = item.fee;
    transaction.target = item.target;
    transaction.usd_price = item.usd_price;
    transaction.id = item.id;
    transaction.partenar = item.partenar;
    transaction.jackpot = item.jackpot;

    return transaction;
};

const getUserTransactions = async (): Promise<Array<Transaction>> => {
    return axios
        .get(`/wallet-history/get-user-wallet-history`)
        .then(({ data }) => {
            const assets: Transaction[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserTransactionsPaginate = async (
    filter: DatatableFilter,
    order_by_date?: string,
): Promise<any> => {
    console.log({ filter });
    // if (filter.wallet) {
    //     const wallets = filter.wallet.split(",").map(Number);
    //     console.log({ wallets });
    // }

    return axios
        .get(`/wallet-history/get-user-wallet-all-history`, {
            params: { ...filter, order_by_date },
        })
        .then(({ data }) => {
            const assets: Transaction[] = data.data.map((item: any) =>
                map(item),
            );

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserTransactionsWithoutPaginate = async (
    filter: DatatableFilter,
): Promise<any> => {
    return axios
        .get(`/wallet-history/get-user-wallet-history-export`, {
            params: { ...filter },
        })
        .then(({ data }) => {
            const transactions: Transaction[] = data.map((item: any) =>
                map(item),
            );
            return transactions;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getLatestTransactions = async (
    id?: string,
    type?: string,
    not_in_type?: any,
): Promise<any> => {
    const filter: DatatableFilter = {
        page: 1,
        size: 10,
        search: "",
        wallet: id,
        type,
        hide_amount_zero: true,
        not_in_type,
    };
    const data = await getUserTransactionsPaginate(filter);

    return data.data;
};

const getSumCreditDebit = async (): Promise<any> => {
    return axios
        .get(`/wallet-history/get-sum-creditdebit`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserTransactionsPaginateAdmin = async (
    filter: DatatableFilter,
    uid: string,
    order_by_date?: string,
): Promise<any> => {
    return axios
        .get(`/wallet-history/history/${uid}`, {
            params: { ...filter, order_by_date },
        })
        .then(({ data }) => {
            const assets: Transaction[] = data.data.map((item: any) =>
                map(item),
            );

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllTransactions = async (): Promise<Array<Transaction>> => {
    return axios
        .get(`/wallet-history/get-all-wallet-history`)
        .then(({ data }) => {
            const assets: Transaction[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllTransactionsPaginate = async (
    filter: DatatableFilter,
): Promise<any> => {
    return axios
        .get(`/wallet-history/get-all-wallet-history`, { params: filter })
        .then(({ data }) => {
            const assets: Transaction[] = data.data.map((item: any) =>
                map(item),
            );

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllTransactionsCount = async (): Promise<any> => {
    return axios
        .get(`/wallet-history/transaction-count`)
        .then(({ data }) => data as OTransactionCount)
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getTotalCommission = async (): Promise<number> => {
    return axios
        .get(`/wallet-history/get-user-total-commission`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getCurrentMonthCommission = async (): Promise<number> => {
    return axios
        .get(`/wallet-history/get-user-current-month-total-commission`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const creditOrDebit = async (
    payload: ITransaction,
    type: TransactionType,
    uid: string,
) => {
    try {
        const result = await axios.post(
            `/user-wallet/${
                type == TransactionType.CREDIT ? "credit" : "debit"
            }/${uid}`,
            {
                ...payload,
            },
        );

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const creditOrDebitParentChild = async (
    payload: ITransactionParentChild,
    type: TransactionType,
    uid: string,
) => {
    try {
        const result = await axios.post(
            `/user-wallet/${
                type == TransactionType.CREDIT ? "credit" : "debit"
            }/child/${uid}`,
            {
                ...payload,
            },
        );

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const getTransactionsByUser = async (
    uid?: string,
): Promise<Array<Transaction>> => {
    return axios
        .get(`/wallet-history/get-user-wallet-history-by-ui`, {
            params: { uid },
        })
        .then(({ data }) => {
            const assets: Transaction[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserCommission = async (): Promise<Array<Transaction>> => {
    return axios
        .get(`/wallet-history/get-commission`)
        .then(({ data }) => {
            const assets: Transaction[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGeneralReport = async (): Promise<any> => {
    return axios
        .get(`/wallet-history/general-report`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const getGeneralReportBonusTotal = async (): Promise<number> => {
    return axios
        .get(`/wallet-history/bonus-total`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const monthlyTotalCBUSDAmount = async (): Promise<any> => {
    return axios
        .get(`/wallet-history/cb`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const lastMonthTotalCBUSDAmount = async (): Promise<number> => {
    return axios
        .get(`/wallet-history/last-month-cb`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGeneralReportTo2Dates = async (
    startDate: Date,
    endDate: Date,
): Promise<any> => {
    return axios
        .get(`/wallet-history/general-report-between-dates`, {
            params: { startDate: startDate, endDate: endDate },
        })
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const formatType = (type: string) => {
    let result = "";
    switch (type) {
        case "deposit":
            result = "Dépôt";
            break;
        case "unstaking":
            result = "Dépôt";
            break;
        case "transferin":
            result = "Dépôt";
            break;
        case "commission":
            result = "Dépôt";
            break;
        case "daily_roi":
            result = "Dépôt";
            break;
        case "daily_knt":
            result = "Dépôt";
            break;
        case "deposit_visa":
            result = "Dépôt";
            break;
        case "deposit_kalypay":
            result = "Dépôt";
            break;
        case "deposit_crypto":
            result = "Dépôt";
            break;
        case "rank_bonus":
            result = "Dépôt";
            break;
        case "pool_bonus":
            result = "Dépôt";
            break;
        case "withdraw":
            result = "Retrait";
            break;
        case "withdraw_crypto":
            result = "Retrait";
            break;
        case "withdraw_kalypay":
            result = "Retrait";
            break;
        case "transferout":
            result = "Retrait";
            break;
        case "order_packbooster":
            result = "Retrait";
            break;
        case "staking":
            result = "Retrait";
            break;
        case "swapout":
            result = "Échange";
            break;
        default:
            break;
    }

    return result;
};

const updateSeen = async (): Promise<any> => {
    return axios.put(`/wallet-history/seen-transaction`).catch((err: any) => {
        return Promise.reject(UtilsService.getAxiosErrorMessage(err));
    });
};

const getSumAmountUsdByUser = async (): Promise<any> => {
    return axios
        .get(`/wallet-history/get-sum-amount-fee-usd-by-user`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAmountHistoryDataGroup = async (): Promise<any> => {
    return axios
        .get(`/wallet-history/get-amount-history-data-group`)
        .then(({ data }) => {
            return data as TypeDateAmount;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getSalesDataLines = async (): Promise<any> => {
    return axios
        .get(`/wallet-history/get-sales-data-lines`)
        .then(({ data }) => {
            return data as DataSales;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const TransactionService = {
    getUserTransactions,
    getTotalCommission,
    getCurrentMonthCommission,
    credit: creditOrDebit,
    getTransactionsByUser,
    getAllTransactions,
    getUserCommission,
    getUserTransactionsPaginate,
    getAllTransactionsPaginate,
    getAllTransactionsCount,
    getGeneralReport,
    getGeneralReportTo2Dates,
    formatType,
    monthlyTotalCBUSDAmount,
    lastMonthTotalCBUSDAmount,
    getUserTransactionsPaginateAdmin,
    getGeneralReportBonusTotal,
    updateSeen,
    getLatestTransactions,
    getSumCreditDebit,
    getUserTransactionsWithoutPaginate,
    creditOrDebitParentChild,
    map,
    getSumAmountUsdByUser,
    getAmountHistoryDataGroup,
    getSalesDataLines,
};

export default TransactionService;
