import React, { useEffect, useMemo, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import User from "../../../Models/User";
import UserService from "../../../Services/User/UserService";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import alert_message from "../../../Services/Alert/AlertMessage";
import GlobalBarChart from "./GlobalBarChart";
import TransactionService from "../../../Services/Wallets/TransactionService";
import ComptabilityService from "../../../Services/Comptability/Comptability";
import StackingChartGlobal from "./StackingChartGlobal";
import AdminWalletsAccounting from "../Wallets/AdminWalletsAccounting";
import AdminFluxFeesAccounting from "../Wallets/AdminFluxFeesAccounting";
import AdminGeneralReportAccounting from "../Wallets/AdminGeneralReportAccounting";
import AdminLineChart from "./AdminLineChart";
import AdminIndividualAccounting from "../Wallets/AdminIndividualAccounting";
import AdminBusinessAccounting from "../Wallets/AdminBusinessAccounting";
import { DataSales } from "../../../Models/Transaction";
import { UserCountData } from "../../../Models/Comptability";
import SubscriptionAbonnement from "../Wallets/SubscriptionAbonnement";
import RaporGeneralProtect from "./RaporGeneralProtect";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);

export interface Raport {
    cb: 0;
    sum_amount_kalypay: 0;
    sum_fidelity: 0;
    sum_withdrawal: 0;
    fee_swap: 0;
    swap: 0;
    sum_commission: 0;
    count_user: {
        total: 0;
        active: 0;
    };
}

const DashboardAdmin: React.FC = () => {
    const { translate } = translator();
    const [users, setUsers] = useState<User[]>([]);
    const [userStats, setUserStats] = useState<UserCountData>();

    const getUserStatsData = async () => {
        try {
            const response = await ComptabilityService.getUserStatsData();
            setUserStats(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    const getUsers = async () => {
        try {
            const res = await UserService.getTopFive();
            setUsers(res.users);
        } catch (err: any) {}
    };

    const [salesData, setSalesData] = useState<DataSales>();
    const getSalesDataLines = async () => {
        try {
            const response = await TransactionService.getSalesDataLines();
            setSalesData(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        getSalesDataLines();
        getUserStatsData();
    }, []);

    return (
        <div>
            {/* <RaporGeneralProtect></RaporGeneralProtect> */}
            <div className="mt-10 md:!mt-7 grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-9">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <AdminGeneralReportAccounting />
                        </div>
                        {/* Evolution des ventes */}
                        <AdminLineChart dataSales={salesData}></AdminLineChart>
                        {/* Rapport des ventes */}
                        <GlobalBarChart dataSales={salesData}></GlobalBarChart>
                        <div className="col-span-12 -mt-5">
                            {/* <AdminSubscriptionsAccounting /> */}
                            <SubscriptionAbonnement />
                        </div>
                        <div className="col-span-12">
                            <AdminIndividualAccounting />
                        </div>
                        <div className="col-span-12">
                            <AdminBusinessAccounting />
                        </div>
                        <div className="col-span-12">
                            <AdminFluxFeesAccounting />
                        </div>
                    </div>
                </div>

                <div className="col-span-12 2xl:col-span-3">
                    <div className=" 2xl:border-l">
                        <div className="grid grid-cols-12 gap-6 2xl:gap-x-0 2xl:pl-6">
                            <div className="box pb-3 col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 xl:mt-12 dark:!border-2 dark:!border-slate-700 dark:!rounded-md">
                                <div className="report-box px-5 py-2">
                                    <div className="grid grid-cols-2">
                                        <div className="ml-auto"></div>
                                    </div>
                                    <div className="mt-2 text-lg font-bold flex justify-left items-center">
                                        {translate(
                                            "ACCOUNTING",
                                            "Users_Statistics",
                                        )}
                                    </div>
                                    <div className="mt-5 text-xl font-medium flex justify-between items-left">
                                        <div className="text-lg font-medium">
                                            {translate("APP", "Number_users")}:{" "}
                                            {userStats
                                                ? userStats.allUsers.total
                                                : "-"}
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-left">
                                        <div className="text-base font-medium text-slate-500">
                                            {translate(
                                                "Admin_Dashboard",
                                                "Active",
                                            )}{" "}
                                            {userStats
                                                ? userStats.allUsers.active
                                                : "-"}{" "}
                                            /{" "}
                                            {userStats
                                                ? userStats.allUsers.inactive
                                                : "-"}{" "}
                                            {translate(
                                                "Admin_Dashboard",
                                                "Inactive",
                                            )}
                                        </div>
                                        <div
                                            className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                            title=""
                                        >
                                            {userStats
                                                ? UtilsService.getPercentValue(
                                                      userStats.allUsers.active,
                                                      userStats.allUsers.total,
                                                  )
                                                : "-"}
                                            %
                                            <i
                                                data-lucide="chevron-up"
                                                className="ml-0.5 h-4 w-1"
                                            ></i>
                                        </div>
                                    </div>

                                    <div className="mt-5 text-xl font-medium flex justify-between items-left">
                                        <div className="text-lg font-medium">
                                            {translate(
                                                "HOME_PAGE",
                                                "Individuals",
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-left">
                                        <div className="text-base text-slate-500">
                                            {translate(
                                                "Admin_Dashboard",
                                                "Active",
                                            )}{" "}
                                            {userStats
                                                ? userStats.individualUsers
                                                      .active
                                                : "-"}{" "}
                                            /{" "}
                                            {userStats
                                                ? userStats.individualUsers
                                                      .inactive
                                                : "-"}{" "}
                                            {translate(
                                                "Admin_Dashboard",
                                                "Inactive",
                                            )}
                                        </div>
                                        <div
                                            className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                            title=""
                                        >
                                            {userStats
                                                ? UtilsService.getPercentValue(
                                                      userStats.individualUsers
                                                          .active,
                                                      userStats.individualUsers
                                                          .total,
                                                  )
                                                : "-"}
                                            %
                                            <i
                                                data-lucide="chevron-up"
                                                className="ml-0.5 h-4 w-1"
                                            ></i>
                                        </div>
                                    </div>
                                    <div className="mt-5 text-xl font-medium flex justify-between items-left">
                                        <div className="text-lg font-medium">
                                            {translate(
                                                "ACCOUNTING",
                                                "Business",
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex justify-between items-left">
                                        <div className="text-base text-slate-500">
                                            {translate(
                                                "Admin_Dashboard",
                                                "Active",
                                            )}{" "}
                                            {userStats
                                                ? userStats.businessUsers.active
                                                : "-"}{" "}
                                            /{" "}
                                            {userStats
                                                ? userStats.businessUsers
                                                      .inactive
                                                : "-"}{" "}
                                            {translate(
                                                "Admin_Dashboard",
                                                "Inactive",
                                            )}
                                        </div>
                                        <div
                                            className="report-box__indicator bg-green-600 tooltip cursor-pointer"
                                            title=""
                                        >
                                            {userStats
                                                ? UtilsService.getPercentValue(
                                                      userStats.businessUsers
                                                          .active,
                                                      userStats.businessUsers
                                                          .total,
                                                  )
                                                : "-"}
                                            %
                                            <i
                                                data-lucide="chevron-up"
                                                className="ml-0.5 h-4 w-1"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 !mt-[1.75rem] md:col-span-6 xl:col-span-4 2xl:col-span-12 p-4 ml-1">
                            <div className="intro-x flex h-10 items-center">
                                <h2 className="truncate text-lg font-medium">
                                    Top 5{" "}
                                    {translate("Admin_Dashboard", "Users")}
                                </h2>
                            </div>
                            <div className="mt-2">
                                {users.map((res: User, key: number) => (
                                    <div className="intro-x" key={key}>
                                        <div className="box mb-3 flex items-center px-5 py-3 dark:!border-2 dark:!border-slate-700 dark:!rounded-md">
                                            <div className="image-fit h-10 w-10 flex-none overflow-hidden rounded-full">
                                                <img
                                                    alt="image"
                                                    src={UtilsService.getProfilPicsUrl(
                                                        res,
                                                    )}
                                                />
                                            </div>
                                            <div className="ml-4 mr-auto">
                                                <div className="font-medium">
                                                    {`${res.first_name} ${res.last_name}`}
                                                </div>
                                                {res.created_at && (
                                                    <div className="mt-0.5 text-xs text-slate-500">
                                                        {UtilsService.getBeautifulFormatedDateWithTime(
                                                            res.created_at,
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="text-green-600">
                                                +{res.cap}€
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12">
                    <AdminWalletsAccounting />
                </div>
                <div className="col-span-5">
                    {/* Répartition des actifs */}
                    <StackingChartGlobal />
                </div>
            </div>
        </div>
    );
};

export default DashboardAdmin;
