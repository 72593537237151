import React from "react";
import { RoleName } from "../../../Models/UserRole";
import { Navigate } from "react-router-dom";

interface WithRoleProtectionProps {
    userRoles: any;
    allowedRoles: RoleName[];
    pageProtege: React.ComponentType<any>;
}

const PageWithRoleProtection = () => {
    return ({
        userRoles,
        allowedRoles,
        pageProtege: PageProtege,
        ...props
    }: WithRoleProtectionProps) => {
        const hasAllowedRole = userRoles.some((role: any) =>
            allowedRoles.includes(role),
        );
        // console.log({ hasAllowedRole });
        if (!hasAllowedRole) {
            return (
                <Navigate
                    to="/dashboard"
                    state={{ from: location.pathname }}
                    replace
                />
            );
        }

        return (
            <>
                <PageProtege {...props} /> {/* Page protégée dynamique */}
            </>
        );
    };
};

export default PageWithRoleProtection;
