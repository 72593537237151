import React from "react";
import AffiliationSvg from "../../Components/Extra/Svg/AffiliationSvg";
import AssetSvg from "../../Components/Extra/Svg/AssetSvg";
import AvisSvg from "../../Components/Extra/Svg/AvisSvg";
import FramerSvg from "../../Components/Extra/Svg/FramerSvg";
import KycSvg from "../../Components/Extra/Svg/KycSvg";
import { ItemListProps } from "./NavAdmin/NavAdmin";
import ShopSvg from "../../Components/Extra/Svg/ShopSvg";
import DashboardSvg from "../../Components/Extra/Svg/DashboardSvg";
import UsersSvg from "../../Components/Extra/Svg/UsersSvg";
import AdvisorSvg from "../../Components/Extra/Svg/AdvisorSvg";
import SavingsSvg from "../../Components/Extra/Svg/SavingsSvg";
import CryptosSvg from "../../Components/Extra/Svg/CryptosSvg";
import FiatSvg from "../../Components/Extra/Svg/FiatSvg";
import WalletSvg from "../../Components/Extra/Svg/WalletSvg";

const MenuListAdmin: ItemListProps[] = [
    {
        name: "Admin_Dashboard.Dashboard",
        path: "/admin/dashboard",
        icon: <DashboardSvg />,
        childs: [],
    },
    {
        name: "Admin_Users.Users",
        path: "/admin/user",
        icon: <UsersSvg />,
        childs: [],
    },
    {
        name: "Marchand",
        path: "/admin/merchant",
        icon: <ShopSvg />,
        childs: [],
    },
    {
        name: "ROLES.ROLES",
        path: "/admin/roles",
        icon: <AdvisorSvg />,
        childs: [],
    },
    {
        name: "Admin_Assets.Assets",
        path: "/admin/assets/list",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="slack"
                data-lucide="slack"
                className="lucide lucide-slack block mx-auto"
            >
                <rect x="13" y="2" width="3" height="8" rx="1.5"></rect>
                <path d="M19 8.5V10h1.5A1.5 1.5 0 1019 8.5"></path>
                <rect x="8" y="14" width="3" height="8" rx="1.5"></rect>
                <path d="M5 15.5V14H3.5A1.5 1.5 0 105 15.5"></path>
                <rect x="14" y="13" width="8" height="3" rx="1.5"></rect>
                <path d="M15.5 19H14v1.5a1.5 1.5 0 101.5-1.5"></path>
                <rect x="2" y="8" width="8" height="3" rx="1.5"></rect>
                <path d="M8.5 5H10V3.5A1.5 1.5 0 108.5 5"></path>
            </svg>
        ),
        childs: [
            {
                name: "Fiat",
                path: "/admin/fiat",
                icon: <FiatSvg />,
                childs: [],
            },
            {
                name: "Cryptos",
                path: "/admin/assets/list",
                icon: <CryptosSvg />,
                childs: [],
            },
            {
                name: "Services.Users_services",
                path: "/admin/assets-earning",
                icon: <SavingsSvg />,
                childs: [],
            },
        ],
    },
    {
        name: "Transactions",
        path: "/admin/transactions",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-card-list"
                viewBox="0 0 16 16"
            >
                {" "}
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
            </svg>
        ),
        childs: [
            {
                name: "Admin_Withdrawal_Request.Withdrawal_Request",
                path: "/admin/withdrawal-request",
                icon: <FramerSvg />,
                childs: [],
            },
            {
                name: "Deposit.Incoming_transfers",
                path: "/admin/deposit-request",
                icon: (
                    <div className="" style={{ transform: "rotate(90deg)" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            icon-name="log-in"
                            data-lucide="log-in"
                            className="lucide lucide-log-in block mx-auto"
                        >
                            <path d="M15 3h4a2 2 0 012 2v14a2 2 0 01-2 2h-4"></path>
                            <polyline points="10 17 15 12 10 7"></polyline>
                            <line x1="15" y1="12" x2="3" y2="12"></line>
                        </svg>
                    </div>
                ),
                childs: [],
            },
            {
                name: "Wallets.All_Transactions",
                path: "/admin/all-transactions",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-card-list"
                        viewBox="0 0 16 16"
                    >
                        {" "}
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                        <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "SUBSCRIPTIONS.Loyalty_list2",
        path: "/admin/subscription",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-receipt-text"
            >
                <path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z" />
                <path d="M14 8H8" />
                <path d="M16 12H8" />
                <path d="M13 16H8" />
            </svg>
        ),
        childs: [
            {
                name: "SUBSCRIPTIONS.Loyalty_list2",
                path: "/admin/subscription-orders",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Fidelity.Level",
                path: "/admin/config",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="settings"
                        data-lucide="settings"
                        className="lucide lucide-settings"
                    >
                        <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Fidelity.Loyalty_list",
                path: "/admin/cards-orders",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-credit-card"
                        viewBox="0 0 16 16"
                    >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"></path>
                    </svg>
                ),
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Affiliation.Affiliation",
        path: "/admin/referrals",
        icon: <AffiliationSvg />,
        childs: [],
        redirectPath: "/admin/referrals/list",
    },
    {
        name: "Informations",
        path: "/admin/informations",
        icon: <AvisSvg />,
        childs: [
            {
                name: "Carrousel",
                path: "/admin/carrousel",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-collection-fill"
                        viewBox="0 0 16 16"
                    >
                        {" "}
                        <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z" />{" "}
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_High_Notice.High_Notice",
                path: "/admin/notice",
                icon: <AvisSvg />,
                childs: [],
            },
        ],
    },
    {
        name: "Admin_Dashboard.Compta",
        path: "/admin/wallet-detail",
        icon: <WalletSvg />,
        childs: [
            {
                name: "Admin_Cards.List",
                path: "/admin/wallet-detail",
                icon: <WalletSvg />,
                childs: [],
            },
            {
                name: "Admin_Assets.Accounting",
                path: "/admin/accounting",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="list"
                        data-lucide="list"
                        className="lucide lucide-list block mx-auto"
                    >
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Services.Customers_savings",
                path: "/admin/savings",
                icon: <SavingsSvg />,
                childs: [],
            },
        ],
    },
    {
        name: "Moderation",
        path: "/admin/moderation/moderation",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="list"
                data-lucide="list"
                className="lucide lucide-list block mx-auto"
            >
                <line x1="8" y1="6" x2="21" y2="6"></line>
                <line x1="8" y1="12" x2="21" y2="12"></line>
                <line x1="8" y1="18" x2="21" y2="18"></line>
                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                <line x1="3" y1="18" x2="3.01" y2="18"></line>
            </svg>
        ),
        childs: [],
    },

    {
        name: "Admin_Settings.Settings",
        path: "/admin/setting",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="settings"
                data-lucide="settings"
                className="lucide lucide-settings"
            >
                <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                <circle cx="12" cy="12" r="3"></circle>
            </svg>
        ),
        childs: [
            {
                name: "Admin_Settings.General",
                path: "/admin/setting/general",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        icon-name="settings"
                        data-lucide="settings"
                        className="lucide lucide-settings"
                    >
                        <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                ),
                childs: [],
            },
            {
                name: "Admin_Assets.Assets",
                path: "/admin/setting/asset/currency",
                icon: <AssetSvg />,
                childs: [],
            },
            {
                name: "KYC",
                path: "/admin/setting/kyc",
                icon: <KycSvg />,
                childs: [],
            },
            {
                name: "Admin_Settings.Payment",
                path: "/admin/setting/payment",
                icon: <KycSvg />,
                childs: [],
            },
        ],
    },
];
export default MenuListAdmin;
