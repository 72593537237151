import { useEffect, useMemo, useState } from "react";
import ComptabilityService from "../../../Services/Comptability/Comptability";
import {
    AbonnementStats,
    AbonnementStatsCalculator,
    propsTypeAbonnement,
} from "../../../Models/Comptability";
import alert_message from "../../../Services/Alert/AlertMessage";
import translator from "../../../Components/Extra/Translation/Translate";
import { useBaseCurrency } from "../../../App/Provider/BaseCurrencyProvider";
import CardInfoAbonnement from "../Dashboard/CardInfoAbonnement";

const SubscriptionAbonnement = () => {
    const { translate } = translator();
    const { base: baseCurrency } = useBaseCurrency();
    const [abonementStats, setAbonementStats] = useState<AbonnementStats>();
    const getStatsAbonnementData = async () => {
        try {
            const response = await ComptabilityService.getStatsAbonnement();
            setAbonementStats(response);
        } catch (err: any) {
            alert_message.error(err);
        }
    };
    const percentAbonnement = useMemo(() => {
        const calculator = new AbonnementStatsCalculator(abonementStats);
        calculator.calculatePercentages();
        return calculator.getStats();
    }, [abonementStats]);
    const data = useMemo(() => {
        const data_part: propsTypeAbonnement = {
            data: percentAbonnement.particulier.particulier,
            gold: percentAbonnement.particulier.gold,
            black: percentAbonnement.particulier.black,
            blue: percentAbonnement.particulier.blue,
        };
        const data_bisness: propsTypeAbonnement = {
            data: percentAbonnement.business.business,
            gold: percentAbonnement.business.gold,
            black: percentAbonnement.business.black,
            blue: percentAbonnement.business.blue,
        };

        return {
            data_part,
            data_bisness,
        };
    }, [percentAbonnement]);

    useEffect(() => {
        getStatsAbonnementData();
    }, []);

    return (
        <div>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 2xl:col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <div className="intro-y mt-10 items-center">
                                <h2 className="mr-auto text-lg font-medium !capitalize">
                                    {translate("ACCOUNTING", "Individual_Subs")}
                                </h2>
                            </div>
                            <div className="mt-2 grid grid-cols-12 gap-6">
                                {percentAbonnement && baseCurrency && (
                                    <>
                                        <CardInfoAbonnement
                                            abonnementStats={
                                                data.data_part as propsTypeAbonnement
                                            }
                                            label="Total"
                                            isFee={true}
                                            baseCurrency={baseCurrency}
                                        />
                                        <CardInfoAbonnement
                                            abonnementStats={
                                                data.data_part as propsTypeAbonnement
                                            }
                                            label="black"
                                            isFee={false}
                                            baseCurrency={baseCurrency}
                                        />
                                    </>
                                )}
                            </div>

                            <div className="col-span-12">
                                <div className="intro-y mt-10 items-center">
                                    <h2 className="mr-auto text-lg font-medium !capitalize">
                                        {translate(
                                            "ACCOUNTING",
                                            "Business_Subs",
                                        )}
                                    </h2>
                                </div>
                                <div className="mt-2 grid grid-cols-12 gap-6">
                                    {percentAbonnement && baseCurrency && (
                                        <>
                                            <CardInfoAbonnement
                                                abonnementStats={
                                                    data.data_bisness as propsTypeAbonnement
                                                }
                                                label="Total"
                                                isFee={true}
                                                baseCurrency={baseCurrency}
                                            />
                                            <CardInfoAbonnement
                                                abonnementStats={
                                                    data.data_bisness as propsTypeAbonnement
                                                }
                                                label="black"
                                                isFee={false}
                                                baseCurrency={baseCurrency}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionAbonnement;
