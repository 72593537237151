import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import CloseMenuSvg from "../../Components/Extra/Svg/CloseMenuSvg";
import OpenMenuSvg from "../../Components/Extra/Svg/OpenMenuSvg";
import UtilsService from "../../Services/UtilsService";
import MenuListAdmin from "./MenuListAdmin";
import ItemListMobile from "../../Layouts/Account/Nav/ItemListMobile";

const SideMobileBo = () => {
    const toggleExanded = (e: any) => {
        e.preventDefault();
    };
    return (
        <div className="mobile-menu md:hidden" id="id-mobile--menu">
            <div className="mobile-menu-bar flex justify-between">
                <div>
                    <NavLink to="/dashboard" className="">
                        <img
                            alt="Logo Infinexia"
                            className="w-[50%]"
                            src={UtilsService.getPulicImage(
                                "/dist/image/Logo-Infinexia.webp",
                            )}
                        />
                    </NavLink>
                </div>
                <div>
                    <NavLink
                        to={`#l`}
                        className="mobile-menu-toggler"
                        onClick={(e) => toggleExanded(e)}
                    >
                        <OpenMenuSvg />
                    </NavLink>
                </div>
            </div>
            <div className="scrollable">
                <NavLink
                    to={`#l`}
                    className="mobile-menu-toggler"
                    onClick={(e) => toggleExanded(e)}
                >
                    <CloseMenuSvg />
                </NavLink>
                <ul className="scrollable__content py-2 relative">
                    <div className="my-2 ml-2 text-white intro-x">Manage</div>
                    <div className="side-nav__devider mdg-side-profile my-6"></div>
                    {MenuListAdmin.map((menu, i) => (
                        <ItemListMobile
                            key={i}
                            name={menu.name}
                            icon={menu.icon}
                            path={menu.path}
                            childs={menu.childs}
                        />
                    ))}

                    <li className="side-nav__devider my-6"></li>
                </ul>
            </div>
        </div>
    );
};

export default SideMobileBo;
