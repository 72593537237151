import React from "react";
import loadable from "@loadable/component";
import Base from "../Layouts/Account/Base";
import PrivateRouteProvider from "./RouteProvider/PrivateRouteProvider";
import LoyaltyConfig from "../Admin/Components/PackBoosterOrder/LoyaltyConfig";
import { Outlet } from "react-router-dom";
import MySubscription from "../Components/Profil/TabElements/Subscription/MySubscription";
import Gouvernance from "../Components/Gouvernance/Gouvernance";
import LoyaltyConfigAdvisor from "../Admin/Components/PackBoosterOrder/LoyaltyConfigAdvisor";
import InfinexiaApi from "../Admin/Components/InfinexiaApi/InfinexiaApi";
import { AllowedCountry } from "../Services/FidelityService/FidelityService";
import RaporGeneralProtect from "../Admin/Components/Dashboard/RaporGeneralProtect";
import AdminFinancialProtected from "../Admin/Components/Wallets/AdminFinancialProtected";
const ChartCryptoPage = loadable(
    () => import("../Components/Wallets/CryptoChart/ViewDetailAsset"),
);

const SwipeAsset = loadable(
    () => import("../Components/Wallets/UserAssetDetail/SwipeAsset"),
);

const Logout = loadable(() => import("../Components/Authentication/Logout"));
const SwipperProvider = loadable(
    () => import("../App/Provider/SwipperProvider"),
);

const Dashboard = loadable(() => import("../Components/Dashboard/Dashboard"));

const Affiliation = loadable(
    () => import("../Components/Profil/TabElements/Affiliation"),
);

const Commande = loadable(() => import("../Components/Order/PackboosterOrder"));

const ListTransactions = loadable(
    () => import("../Components/Transactions/ListTransactions"),
);

const Swap = loadable(() => import("../Components/Wallets/Swap"));

const StackingNew = loadable(
    () => import("../Components/Stacking/StackingNew"),
);

const SwpTransfert = loadable(
    () => import("../Components/Wallets/Transaction/SwpTransfert"),
);

const Withdraw = loadable(() => import("../Components/Wallets/Withdraw"));

const Deposit = loadable(() => import("../Components/Wallets/Deposit"));

const AffiliationUser = loadable(
    () => import("../Components/Affiliation/Affiliation"),
);

const Fidelity = loadable(() => import("../Components/Fidelity/Fidelity"));
const NewWallet = loadable(() => import("../Components/Wallets/NewWallet"));
const WalletCrypto = loadable(
    () => import("../Components/Wallets/WalletCrypto"),
);
const Achat = loadable(() => import("../Components/Wallets/Achat"));

const IndexSetting = loadable(
    () => import("../Components/SettingSecurity/Setting/IndexSetting"),
);
const SettingSecurity = loadable(
    () => import("../Components/SettingSecurity/SettingSecurity"),
);
const NewStaking = loadable(() => import("../Components/Stacking/NewStaking"));

const Earn = loadable(() => import("../Components/Earn/Earn"));

const ListAccount18 = loadable(
    () => import("../Components/Account18/ListAccount18"),
);
const TransactionForm = loadable(
    () => import("../Components/Account18/TransactionForm"),
);
const JoinAccount = loadable(
    () => import("../Components/JoinAccount/JoinAccount"),
);

const ListUser = loadable(
    () => import("../Admin/Components/User/List/ListUser"),
);

const UserDetail = loadable(
    () => import("../Admin/Components/User/List/TransactionForm"),
);

const Moderation = loadable(
    () => import("../Admin/Components/Moderation/Moderations"),
);

const ModerationTab = loadable(
    () => import("../Admin/Components/Moderation/ModerationTab"),
);

const RequestReset2FA = loadable(
    () => import("../Admin/Components/Reset-2fa/Reset2FA"),
);

const DebitCredit = loadable(
    () => import("../Admin/Components/Request-debit-credit/DebitCreditRequest"),
);

const SubAccountRequest = loadable(
    () => import("../Admin/Components/SubAccountRequest/SubAccountRequest"),
);

const AdminListTransactions = loadable(
    () => import("../Admin/Components/Transactions/ListTransactions"),
);

// const ListWithdrawalRequest = loadable(
//     () => import("../Admin/Components/WithdrawalRequest/ListWithdrawalRequest"),
// );

const ListWithdrawalRequestProtected = loadable(
    () =>
        import(
            "../Admin/Components/WithdrawalRequest/ListWithdrawalRequestProtected"
        ),
);
const FidelityAdv = loadable(
    () => import("../Admin/Components/PackBoosterOrder/Fidelity"),
);

// const ManualDepositTransaction = loadable(
//     () => import("../Admin/Components/ManualDepositTransactions/List"),
// );

const ManualDepositTransactionProtected = loadable(
    () => import("../Admin/Components/ManualDepositTransactions/ListProtected"),
);

const OrderCardTabs = loadable(
    () => import("../Admin/Components/OrderCard/OrderCardTabs"),
);

const OrderCardAdmin = loadable(
    () => import("../Admin/Components/OrderCard/OrderCardAdmin"),
);

const BillEstimate = loadable(
    () => import("../Components/Bill-estimate/BillEstimate"),
);
const Bill = loadable(() => import("../Components/Bill-estimate/Bill/Bill"));
const Estimate = loadable(
    () => import("../Components/Bill-estimate/Estimate/Estimate"),
);

const Invoice = loadable(
    () => import("../Components/ReactInvoice/ReactInvoice"),
);

const ApiInfinexiaTab = loadable(
    () => import("../Admin/Components/InfinexiaApi/infinexiaApiTab"),
);

const ApiInfinexiaTransaction = loadable(
    () => import("../Admin/Components/InfinexiaApi/infinexiaApiTransaction"),
);

const MyCard = loadable(() => import("../Components/card/Card"));

const ApiAccessRequest = loadable(
    () => import("../Admin/Components/api-access-request/ApiAccessRequest"),
);

const AccountRoutes: any = [
    {
        element: <PrivateRouteProvider />,
        children: [
            {
                path: "logout",
                element: <Logout />,
            },
            {
                element: <Base />,
                children: [
                    {
                        element: <SwipperProvider />,
                        children: [
                            {
                                path: "/dashboard",
                                element: <Dashboard />,
                                children: [],
                            },

                            {
                                path: "",
                                element: <SettingSecurity />,
                                children: [
                                    {
                                        path: "settings",
                                        element: <IndexSetting />,
                                    },
                                    {
                                        path: "referrals",
                                        element: <Affiliation />,
                                    },
                                    {
                                        path: "my-subscription",
                                        element: <MySubscription />,
                                    },
                                    {
                                        path: "actions",
                                        element: <Gouvernance />,
                                    },
                                ],
                            },

                            {
                                path: "",
                                children: [
                                    {
                                        path: "",
                                        children: [
                                            {
                                                index: true,
                                                path: "accounts",
                                                element: <NewWallet />,
                                            },
                                            {
                                                index: true,
                                                path: "cryptos",
                                                element: <WalletCrypto />,
                                            },
                                            {
                                                path: "exchange/:origin",
                                                element: <Swap />,
                                            },
                                            {
                                                path: "exchange/",
                                                element: <Swap />,
                                            },
                                            {
                                                path: "deposit/:origin",
                                                element: <Deposit />,
                                            },
                                            {
                                                path: "deposit/",
                                                element: <Deposit />,
                                            },
                                            {
                                                path: "buy/:origin",
                                                element: <Achat />,
                                            },
                                            {
                                                path: "buy/",
                                                element: <Achat />,
                                            },
                                            {
                                                path: "withdraw/:origin",
                                                element: <Withdraw />,
                                            },
                                            {
                                                path: "withdraw/",
                                                element: <Withdraw />,
                                            },
                                            {
                                                path: "swap-transaction",
                                                element: <SwpTransfert />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "savings-plans",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <Earn />,
                                    },
                                    {
                                        path: "new",
                                        element: <StackingNew />,
                                    },
                                ],
                            },
                            {
                                path: "my-savings",
                                element: <NewStaking />,
                            },
                            {
                                path: "estimates-invoices",
                                element: <BillEstimate />,
                                children: [
                                    {
                                        path: "",
                                        element: <Estimate />,
                                    },
                                    {
                                        path: "invoices",

                                        children: [
                                            {
                                                path: "",
                                                element: <Bill />,
                                            },
                                            {
                                                path: "new",
                                                element: <Invoice />,
                                            },
                                        ],
                                    },
                                    {
                                        path: "estimates",

                                        children: [
                                            {
                                                path: "",
                                                element: <Estimate />,
                                            },
                                            {
                                                path: "new",
                                                element: <Invoice />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "api-infinexia",
                                element: <ApiInfinexiaTab />,
                                children: [
                                    {
                                        path: "",
                                        index: true,
                                        element: <InfinexiaApi />,
                                    },
                                    {
                                        path: "general",
                                        element: <InfinexiaApi />,
                                    },

                                    {
                                        path: "payments",
                                        element: <ApiInfinexiaTransaction />,
                                    },
                                ],
                            },
                            {
                                path: "child-accounts",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <ListAccount18 />,
                                    },
                                    {
                                        path: "transact/:child_uid",
                                        element: <TransactionForm />,
                                    },
                                ],
                            },
                            {
                                path: "/accounts/:id",
                                element: <SwipeAsset />,
                                children: [],
                            },

                            {
                                path: "/asset/:abbrev",
                                element: <ChartCryptoPage />,
                                children: [],
                            },
                        ],
                    },
                    {
                        element: (
                            <div className={"px-[10px] md:!px-[22px]"}>
                                {" "}
                                <Outlet />{" "}
                            </div>
                        ),
                        children: [
                            {
                                path: "subscription",
                                element: <Fidelity />,
                            },
                        ],
                    },
                    {
                        path: "cards",
                        element: <MyCard />,
                    },
                    {
                        element: (
                            <div className={"px-[22px]"}>
                                {" "}
                                <Outlet />{" "}
                            </div>
                        ),
                        children: [
                            {
                                path: "transactions",
                                element: <ListTransactions />,
                            },
                            {
                                path: "transactions/:wallet",
                                element: <ListTransactions />,
                            },
                            {
                                path: "orders",
                                element: <Commande />,
                            },
                            {
                                path: "earn",
                                element: <Earn />,
                            },
                            {
                                path: "affiliation",
                                element: <AffiliationUser />,
                            },
                            {
                                path: "wallet-online",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <NewWallet />,
                                    },
                                    {
                                        index: true,
                                        path: "",
                                        element: <WalletCrypto />,
                                    },
                                    {
                                        path: "swap/:origin",
                                        element: <Swap />,
                                    },
                                    {
                                        path: "deposit/:origin",
                                        element: <Deposit />,
                                    },
                                    {
                                        path: "achat/:origin",
                                        element: <Achat />,
                                    },
                                    {
                                        path: "withdraw/:origin",
                                        element: <Withdraw />,
                                    },
                                    {
                                        path: "swap-transaction",
                                        element: <SwpTransfert />,
                                    },
                                ],
                            },

                            {
                                path: "switch-join-account",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <JoinAccount />,
                                    },
                                ],
                            },
                            {
                                path: "user",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <ListUser front={true} />,
                                    },
                                    {
                                        path: "transact/:id",
                                        element: <UserDetail front={true} />,
                                    },
                                ],
                            },
                            {
                                path: "moderation",
                                element: <ModerationTab />,
                                children: [
                                    {
                                        path: "",
                                        index: true,
                                        element: <Moderation />,
                                        children: [],
                                    },
                                    {
                                        path: "moderation",
                                        element: <Moderation />,
                                        children: [],
                                    },
                                    {
                                        path: "reset-2fa",
                                        element: <RequestReset2FA />,
                                        children: [],
                                    },
                                    {
                                        path: "debit-credit",
                                        element: <DebitCredit />,
                                        children: [],
                                    },
                                ],
                            },
                            {
                                path: "api-access-request",
                                element: <ApiAccessRequest />,
                                children: [],
                            },
                            {
                                path: "subaccounts",
                                element: <SubAccountRequest />,
                                children: [],
                            },
                            {
                                path: "all-transactions",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <AdminListTransactions />,
                                    },
                                ],
                            },
                            {
                                path: "withdrawal-request",
                                // element: <ListWithdrawalRequest />,
                                element: <ListWithdrawalRequestProtected />,
                            },
                            {
                                path: "cards-orders",
                                children: [
                                    {
                                        path: "",
                                        element: <LoyaltyConfig />,
                                        children: [
                                            {
                                                path: "",
                                                element: <FidelityAdv />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "deposit-request",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        // element: <ManualDepositTransaction />,
                                        element: (
                                            <ManualDepositTransactionProtected />
                                        ),
                                    },
                                ],
                            },
                            {
                                path: "order-card",
                                element: <OrderCardTabs isAdmin={false} />,
                                children: [
                                    {
                                        path: "eu-cards-orders",
                                        children: [
                                            {
                                                path: ":status",
                                                index: true,
                                                element: <OrderCardAdmin />,
                                            },
                                        ],
                                    },
                                    {
                                        path: "world-cards-orders",
                                        children: [
                                            {
                                                path: ":status",
                                                index: true,
                                                element: (
                                                    <OrderCardAdmin
                                                        type={
                                                            AllowedCountry.WORLD
                                                        }
                                                    />
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "subscriptions-orders-list",
                                children: [
                                    {
                                        path: "",
                                        element: <LoyaltyConfigAdvisor />,
                                        children: [
                                            {
                                                index: true,
                                                path: "",
                                                element: <FidelityAdv />,
                                            },
                                            {
                                                path: "eu",
                                                element: <FidelityAdv />,
                                            },
                                            {
                                                path: ":world",
                                                element: (
                                                    <FidelityAdv
                                                        type={
                                                            AllowedCountry.WORLD
                                                        }
                                                    />
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "/general-report",
                                // element: <DashboardAdmin />,
                                element: <RaporGeneralProtect />,
                            },
                            {
                                path: "/financial-accounting",
                                // element: <AdminFinancial />,
                                element: <AdminFinancialProtected />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default AccountRoutes;
